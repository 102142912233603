import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import CategoryBlock from 'components/categories/CategoryBlock';
import Loader from 'components/atoms/Loader';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props.children, ...utils};
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [categories, setCategories] = useState(null);
  const [categoryCount, setCategoryCount] = useState(null);
  const [noCategories, setNoCategories] = useState(true);
  const [redirect, setRedirect] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id')) {
      setOrganizationID(props.site.persona.organization_id);
    }
    if(props.objExists(props.site, 'site_language')) {
      setLanguageCode(props.site.site_language);
    }else{
      setLanguageCode('en');  
    }
  }, [props.site]);
  
  useEffect(() => {
    if(organizationID && props.objExists(props.site, 'persona.organization_name')) {
      document.title = `${props.site.persona.organization_name} : Categories`;
    }
  }, [organizationID]);

  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.store, `categories`)) && 
        props.store.categories.length > 0 &&
        props.store.categories !== categories) {
          setCategories(props.store.categories);
          setCategoryCount(props.store.categories.length);
    }
  }, [languageCode, props.store]);

  return (
    <div className="set-container">
      {redirect && <Redirect to={redirect} />}
      <h2>Categories</h2>
      {categories ? 
          <div className={`grid blocks${categories.length > 7 ? ' sml' : ''}`}>
            {Array.isArray(categories) &&
              categories.map((category, index) => {
                return (
                  <CategoryBlock {...props}
                      key={`cb${index}`}
                      languageCode={languageCode}
                      category={category}
                      link={`/category/${category.category_id}`} /> 
                )
              })
            }
          </div>
        : noCategories ? 
            <p>There are no categories for {props.objExists(props.site, 'persona.organization_name') ? props.site.persona.organization_name : 'your organization' }</p>
          :
            <Loader label="Loading categories" />
      }
    </div>
  )
}
