import React, {useEffect, useState, useRef, Fragment}  from 'react';
import { useParams, Redirect } from "react-router";

import * as utils from 'methods/site';
import * as verification from 'components/cart/cart-verification';

import ProjectPricing from 'components/cart/ProjectPricing';
import CheckoutAccount from 'components/cart/CheckoutAccount';
import CheckoutBilling from 'components/cart/CheckoutBilling';
import CheckoutShipping from 'components/cart/CheckoutShipping';
import Button from 'components/forms/elements/Button';
import Link from 'components/navigation/Link';
import Loader from 'components/atoms/Loader';
import CartErrorConsole from 'components/cart/CartErrorConsole';

export default (props) => {
  props = {...props.children, ...utils};
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userContact, setUserContact] = useState(null);
  const [userLocations, setUserLocations] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [organizationCode, setOrganizationCode] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [languageID, setLanguageID] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [project, setProject] = useState(null);
  const [updateProject, setUpdateProject] = useState(null);
  const [shipmentsRequired, setShipmentsRequired] = useState(null);
  const [shippingOptions, setShippingOptions] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [restrictLocations, setRestrictLocations] = useState(null);
  const [orderMultiship, setOrderMultiship] = useState(null);
  const [hidePricing, setHidePricing] = useState(null);
  const [billingContact, setBillingContact] = useState(null);
  const [verified, setVerified] = useState(null);
  const [missingValues, setMissingValues] = useState(null);
  const [errorFlags, setErrorFlags] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  
  // HOOKS
  // USER:
  // useEffect(() => {
  //   props.cartRemoveVal(`project.orders[0].shipping.locations`);
  // }, []);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id')) {
      setUser(props.user);
      setUserID(props.user.user_id);
      
      if(localStorage.getItem('token')) {
        setToken(localStorage.getItem('token'));
      }else if(sessionStorage.getItem('token')) {
        setToken(sessionStorage.getItem('token'));
      }
    }
    
    if(props.objExists(props.user, 'user_id')) {
      if(props.objGetValue(props.user, `user_roles.1.role_id`) === 1) {
        setIsSuper(true);
      }
    }
    
  }, [props.user]);
  
  useEffect(() => {
    // SETTING USER CONTACT
    if(user && props.objExists(user, 'contact.contact_name_first')) {
      setUserContact(user.contact);
    }
    
    // SETTING DEFAULT LOCATION IF AVAILABLE
    if(user && Array.isArray(props.objGetValue(user, 'locations'))) {
      const defaultLoc = user.locations.find(loc => loc.location_user_default === 1);
      if(defaultLoc) {
        setDefaultLocation(defaultLoc);  
      }else if(props.objExists(user, 'locations[0]')) {
        setDefaultLocation(user.locations[0]);
      }
    }
    
    // GET AVAILABLE USER LOCATIONS
    if(Array.isArray(props.objGetValue(props.user, `locations`)) &&
      props.user.locations.length > 0 &&
      props.user.locations !== userLocations) {
        setUserLocations(props.user.locations);
      }
  }, [user]);
  
  // PROJECT:
  useEffect(() => {
    if(props.objExists(props.cart, 'project') && 
        props.cart.project !== project) {
      setProject({...props.cart.project});
    }else if(!props.objExists(props.cart, 'project') &&
              sessionStorage.getItem('corePrjct')) {
      const sesProject = JSON.parse(sessionStorage.getItem('corePrjct'));
      if(props.objExists(sesProject, `orders[0].items[0]`)) {
        props.cartSetValue('project', sesProject);
        setProject(sesProject);
      }else{
        sessionStorage.removeItem('corePrjct');
      }
    }else if(sessionStorage.getItem('corePrjct') &&
            JSON.parse(sessionStorage.getItem('corePrjct')) !== props.cart.project) {
      props.cartSetValue('project', JSON.parse(sessionStorage.getItem('corePrjct')));
      setProject(JSON.parse(sessionStorage.getItem('corePrjct')));
    }else if(!props.objExists(props.cart, 'project') &&
              !sessionStorage.getItem('corePrjct') &&
              project) {
      setProject(null);
    }
    if(updateProject) {
      setUpdateProject(false);
    }
  }, [props.cart.project, verified, updateProject, sessionStorage.getItem('corePrjct')]);
  
  useEffect(() => {
    if(props.objExists(props.cart, `project`) && 
        JSON.stringify(props.cart.project) !== sessionStorage.getItem('corePrjct')) {
      sessionStorage.setItem('corePrjct', JSON.stringify(props.cart.project));
    }
  }, [props.cart.project]);
  
  // AUTOPOPULATING ORDERS WITH USER CONTACT
  useEffect(() => {
    if(userContact && project &&
      Array.isArray(props.objGetValue(project, 'orders'))) {
        project.orders.map((pOrder, index) => {
          // LEGACY SHIPPING
          if(!props.objExists(pOrder, 'shipping.contact')) {
            props.cartSetValue(`project.orders[${index}].shipping.contact`, userContact);
          }
          
          // UPDATED MULTIPLE SHIPPING
          if(!props.objExists(pOrder, `shipping.locations[0].contact_name_first`)) {
            let mergedContact = {};
            if(props.objGetValue(pOrder, `shipping.locations[0]`)) {
              mergedContact = 
                {...mergedContact, 
                ...props.objGetValue(pOrder, `shipping.locations[0]`)};
            }
            mergedContact = {...mergedContact, ...userContact};
            props.cartAssignValue(`project.orders[${index}].shipping.locations[0]`, mergedContact);
          }
          
        });
      }
  }, [userContact, project]);
  
  // AUTOPOPULATING ORDERS WITH DEFAULT LOCATION IF AVAILABLE
  useEffect(() => {
    if(defaultLocation && 
      Array.isArray(props.objGetValue(project, 'orders'))) {
        project.orders.map((pOrder, index) => {
          
          // UPDATED MULTIPLE SHIPPING
          if(!props.objExists(project, `orders[${index}].shipping.locations[0]`)) {
            props.cartSetValue(`project.orders[${index}].shipping.locations[0]`, defaultLocation);
          }else if(!props.objExists(project, `orders[${index}].shipping.locations[0].location_address_1`)) {
            const mergedLocation = {...props.objGetValue(project, `orders[${index}].shipping.locations[0]`), 
                                  ...defaultLocation};
            props.cartSetValue(`project.orders[${index}].shipping.locations[0]`, mergedLocation);
          }
        });
      }
  }, [defaultLocation, project]);
  
  // * * * * * * * * * * * * * * * * * * * * * * 
  
  // SITE
  // GET SITE LANGUAGE
  useEffect(() => {
    if(props.objExists(props.site, 'site_language') &&
        props.site.site_language !== languageCode) {
      setLanguageCode(props.site.site_language);
    }else if(!languageCode && !props.objExists(props.site, 'site_language')) {
      setLanguageCode('en');
    }
  }, [props.site]);
  
  
  useEffect(() => {
    if(props.objExists(props.site, `preferences.order_multiship`) &&
      props.site.preferences.order_multiship !== orderMultiship) {
        setOrderMultiship(props.site.preferences.order_multiship);
    }
    
    if(props.objExists(props.site, `preferences.restrict_user_locations`) &&
      !restrictLocations) {
        setRestrictLocations(props.site.preferences.restrict_user_locations);
    }
    
  }, [props.site.preferences]);
  
  // ORG:
  useEffect(() => {
    // ORG ID
    if(props.objExists(props.site, 'persona.organization_id') &&
        props.site.persona.organization_id !== organizationID) {
          setOrganizationID(props.site.persona.organization_id);
    }else if(!props.objExists(props.site, 'persona.organization_id') &&
              organizationID) {
          setOrganizationID(null); 
    }
    
    // ORG NAME
    if(props.objExists(props.site, 'persona.organization_name') &&
        props.site.persona.organization_name !== organizationName) {
          setOrganizationName(props.site.persona.organization_name);
    }else if(!props.objExists(props.site, 'persona.organization_name') &&
              organizationName) {
          setOrganizationName(null); 
    }
    
    // ORG CODE
    if(props.objExists(props.site, 'persona.organization_code') &&
        props.site.persona.organization_code !== organizationCode) {
          setOrganizationCode(props.site.persona.organization_code);
    }else if(!props.objExists(props.site, 'persona.organization_code') &&
              organizationCode) {
          setOrganizationCode(null); 
    }
    
    // SET SHIPPING OPTIONS
    if(languageCode && 
        Array.isArray(props.objGetValue(props.site, `persona.shipping_options.${languageCode}`)) &&
        props.site.persona.shipping_options[languageCode] !== shippingOptions) {
        setShippingOptions(props.site.persona.shipping_options[languageCode]);
    }
    
    // SET PAYMENT OPTIONS
    if(props.objExists(props.site, 'persona.payment_options')) {
      let payOptions = [];
      if(props.site.persona.payment_options.public) {
        payOptions = [...props.site.persona.payment_options.public];
      } 
      if(props.site.persona.payment_options.private &&
          Array.isArray(props.site.persona.payment_options.private)) {
            props.site.persona.payment_options.private.map(pOpt => {
              if(!payOptions.find(opt => opt.option_id === pOpt.option_id)) {
                payOptions.push(pOpt);
              }
            })
      }
      if(payOptions.length > 0) {
        setPaymentOptions(payOptions);
      }
    }
    
    // SITE BILLING CONTACT
    if(props.objExists(props.site, 'persona.site_billing') && 
        billingContact !== props.site.persona.site_billing) {
      setBillingContact(props.site.persona.site_billing);
    }
    
  }, [props.site.persona, languageCode]);
  
  // ORG PREFERENCES
  useEffect(() => {
    // CHECK FOR HIDE PRICING PREFERENCE
    if(props.site.preferences && props.objGetValue(props.site, 'preferences.hide_pricing') === true &&
      hidePricing !== true) {
      setHidePricing(true);
    }
  }, [props.site.preferences]);
  
  // SETTING PROJECT ORG ID
  useEffect(() => {
    if(organizationID && project && 
        props.objGetValue(project, 'organization_id') !== organizationID) {
        props.cartSetValue('project.organization_id', organizationID); 
    }
  }, [organizationID, project]);
  
  // SETTING PROJECT ORG NAME
  useEffect(() => {
    if(organizationName && project && 
        props.objGetValue(project, 'organization_name') !== organizationName) {
        props.cartSetValue('project.organization_name', organizationName);
    }
  }, [organizationName, project]);
  
  // SETTING PROJECT ORG CODE
  useEffect(() => {
    if(organizationCode && project && 
        props.objGetValue(project, 'organization_code') !== organizationCode) {
        props.cartSetValue('project.organization_code', organizationCode);
    }
  }, [organizationCode, project]);
  
  useEffect(() => {
    if(Array.isArray(paymentOptions) && 
        paymentOptions.length === 1) {
          
      // SET PROJECT BILLING TO OPTION
      // WILL NEED TO EXPAND FOR NON-BILL ACCOUNT OR MULTIPLE METHODS
      if(props.objGetValue(project, 'billing.order_payment_method') !== paymentOptions[0].option_name) {
        props.cartSetValue(`project.billing.order_payment_method`, paymentOptions[0].option_name);
      } 
      if(paymentOptions[0].option_name === 'Bill Account') {
        if(props.objExists(billingContact, 'contact.contact_name_first') &&
            !props.objExists(project, `billing.contact.contact_name_first`)) {
              props.cartSetValue(`project.billing.contact`, billingContact.contact);
        }
        
        if(props.objExists(billingContact, 'location.location_address_1') &&
            !props.objExists(project, `billing.location.location_address_1`)) {
              props.cartSetValue(`project.billing.location`, billingContact.location);
        }
      }else{
        
      }    
      
      // SET ORDER BILLING
      if(project && Array.isArray(project.orders)) {
        project.orders.map((pOrder, index) => {
          if(props.objGetValue(pOrder, 'order_payment_method') !== paymentOptions[0].option_name) {
            props.cartSetValue(`project.orders[${index}].order_payment_method`, paymentOptions[0].option_name);
            
            if(paymentOptions[0].option_name === 'Bill Account') {
              if(props.objExists(billingContact, 'contact.contact_name_first') &&
                  !props.objExists(project, `orders[${index}].billing.contact.contact_name_first`)) {
                    props.cartSetValue(`project.orders[${index}].billing.contact`, billingContact.contact);
              }
              
              if(props.objExists(billingContact, 'location.location_address_1') &&
                  !props.objExists(project, `orders[${index}].billing.location.location_address_1`)) {
                    props.cartSetValue(`project.orders[${index}].billing.location`, billingContact.location);
              }
            }
          }
        })
      }
    }
  }, [paymentOptions, billingContact, project]);
  
  
  
  // GET SITE LANGUAGE ID && PROJECT LANGUAGE CODE
  useEffect(() => {
    if(languageCode) {
      if(props.getLanguageId(languageCode) !== languageID) {
        setLanguageID(props.getLanguageId(languageCode));
      }
      if(!props.objExists(project, 'langauge_code')) {
        props.cartSetValue('project.language_code', languageCode);
      }
    }
  }, [languageCode, project]);
  
  // SET PROJECT LANGUAGE ID
  useEffect(() => {
    if(languageID && !props.objExists(project, 'langauge_id')) {
      props.cartSetValue('project.language_id', languageID);
    }
  }, [languageID, project]);
  
  // * * * * * * * * * * * * * * * * * * * * * * 
  
  // CART ITEMS:
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.cart, 'items')) &&
        props.cart.items.length > 0 &&
        props.cart.items !== cartItems) {
        setCartItems(props.cart.items);
        props.cartDeleteVal('items');
        sessionStorage.removeItem('coreCRT');
    }
  }, [props.cart.items]);
  
  useEffect(() => {
    if(cartItems && project) {
      let orderItems = [];
      if(Array.isArray(props.objGetValue(project, `orders[0].items`))) {
        orderItems.push(...project.orders[0].items);
      }
      orderItems.push(...cartItems);
      props.cartAssignValue('project.orders[0].items', orderItems);
      props.cartSetValue('cartCount', orderItems.length);
      setCartItems(null);
    }
  }, [cartItems, project]);
  
  useEffect(() => {
    if(project && Array.isArray(project.orders)) {
      let orderShipments = {};
      project.orders.map((order, index) => {
        if(Array.isArray(order.items)) {
          
          // if(props.objExists(project, `orders[${index}].shipping.locations[0].items`) ||
          //   Array.isArray(project.orders[index].shipping.locations[0].items)) {
          //     props.cartAssignValue(`project.orders[${index}].shipping.locations[0].items`, project.orders[0].items);
          // }
          
          let shippedItem = false;
          order.items.map((item, iIndex) => {
              if(!props.itemIsDigital(item)) {
                shippedItem = true;
              }
              
              // CHECKING THAT LOCATION QUANTITIES ARE SET FOR ALL ORDER ITMES IN LOCATION [0]
              let locQtys = {};
              if(props.objGetValue(item, `location_quantities.0`) <= 0) {
                locQtys[0] = parseInt(item.product_quantity);
                
                Object.entries(locQtys).length > 0 &&
                props.cartAssignValue(`project.orders[${index}].items[${iIndex}].location_quantities`, locQtys);
              }
          });
          orderShipments[index] = {shipmentRequired: shippedItem};
        }
      });
      setShipmentsRequired(orderShipments);
    }
  }, [project]);
  
  useEffect(() => {
    if(project && shipmentsRequired && shippingOptions) {
      const optionDigital = shippingOptions.find(opt => opt.option_name && opt.option_name.toLowerCase() === 'digital');
      const optionsPhysical = shippingOptions.filter(opt => opt.option_name && opt.option_name.toLowerCase() !== 'digital');
      if(Array.isArray(project.orders)) {
        project.orders.map((pOrder, index) => {
          if(Array.isArray(props.objGetValue(pOrder, `shipping.locations`))) {
            pOrder.shipping.locations.map((sLoc, slIndex) => {
             
             if(!props.objExists(sLoc, 'location_shipping_method')) {
                props.cartSetValue(`project.orders[${index}].shipping.locations[${slIndex}].location_shipping_method`, props.objGetValue(shipmentsRequired, `${index}.shipmentRequired`) ? 
                  optionsPhysical[0].option_name : 
                  optionDigital.option_name)
              }
              
            });
          }
        })
      }
    }
    
  }, [project, shipmentsRequired, shippingOptions]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.cart, `project.orders`))) {
      props.cart.project.orders.map((order, index) => {
        if(Array.isArray(order.items)) {
          order.items.map((item, iIndex) => {
            if(item.location_quantities) {
              let productQty = 0;
              Object.entries(item.location_quantities).map((locQty, lqIndex) => {
                if(parseInt(locQty[1]) > 0) {
                  productQty += parseInt(locQty[1]);
                }
              });
              if(productQty !== parseInt(props.objGetValue(props.cart, `project.orders[${index}].items[${iIndex}].product_quantity`))) {
                // Update product_quantity
                props.cartSetValue(`project.orders[${index}].items[${iIndex}].product_quantity`, productQty);
                // Update product_price
                if(props.objExists(item.details[languageCode], `pricing`)) {
                  let pricingOption;
                  let pricing;
                  let discounts;
                  let pageCount;
                  let pagePricingOption;
                  let pageBasePrice;
                  let pageDiscounts;
                  let productCharge;
                  if(languageCode && item.product_pricing_option && item.product_pricing_option !== '') {
                      item.details[languageCode].pricing && Array.isArray(item.details[languageCode].pricing) && item.details[languageCode].pricing.length > 0 &&
                        item.details[languageCode].pricing.map((price, index) => {
                          if(price.pricing_option === item.product_pricing_option) {
                            pricingOption = index;
                            pricing = price;
                            if (pricing.discounts && Array.isArray(pricing.discounts) && pricing.discounts.length > 0) {
                              discounts = pricing.discounts;
                            }
                          }
                        })
                  }
                  if(item && item.page_count && item.page_count > 0) {
                    pageCount = item.page_count;
                  }
                  if(item && item.page_pricing_option) {
                    pagePricingOption = item.page_pricing_option;
                  }
                  if(pageCount && pagePricingOption) {
                    pageBasePrice = parseFloat(pageCount * pagePricingOption.pricing_base_price);
                    pageDiscounts = pagePricingOption.discounts;
                  }
                  if(props.objExists(props.site, `persona.charges.product[0].charge_value`)) {
                    productCharge = props.site.persona.charges.product[0].charge_value;
                  }
                  if(props.objExists(item, `details.${languageCode}.charges.charge_value`)) {
                    productCharge = item.details[languageCode].charges.charge_value;
                  }
                  const price = props.priceCalc(productQty, 
                                                props.numberInput(pricing.pricing_unit_price),
                                                props.numberInput(pricing.pricing_base_price),
                                                discounts,
                                                pricing.pricing_round,
                                                pageBasePrice,
                                                pageDiscounts,
                                                productCharge
                                              );
                  props.cartSetValue(`project.orders[${index}].items[${iIndex}].product_price`, price);
                }
              }
            }
          })
        }
      })
    }
    
  }, [project, verified]);
  
  
  // * * * * * * * * * * * * * * * * *
  
  // VERIFICATION
  useEffect(() => {
    if(project && shipmentsRequired) {
      let verResults = true;
      let missingInfo = [];
      verification.projectReq.map(reqField => {
        
        if(!props.objExists(project, reqField.name)) {
          verResults = false;
          missingInfo.push(`project.${reqField.name}`);
        }
      });
      
      if(Array.isArray(project.orders)) {
        project.orders.map((pOrder, index) => {
          const orderType = props.objExists(shipmentsRequired, `${index}`) && shipmentsRequired[index].shipmentRequired ? 'physical' : 'digital';
          verification.orderReq[orderType].map(reqField => {
            Array.isArray(props.objGetValue(pOrder, `shipping.locations`)) &&
            pOrder.shipping.locations.map((sLoc, slIndex) => {
              if(!props.objExists(pOrder, reqField.name.replace('[x]', `[${slIndex}]`))) {
                verResults = false;
                missingInfo.push(`orders[${index}].${reqField.name.replace('[x]', `[${slIndex}]`)}`);
              }  
            })
            
          })
        })
      }else{
        verResults = false;
      }
      
      if(verResults) {
        setVerified(true);
      }else{
        if(verified) {
          setVerified(false);
        }
      }
     // console.log("MISSING INFO?", missingInfo);
     if(missingInfo && 
        JSON.stringify(missingInfo) !== JSON.stringify(missingValues)) {
       setMissingValues(missingInfo);
     }
    }else{
      // console.log("skipped verification", shipmentsRequired, project);
    }
  }, [project, shipmentsRequired, props.cart]);
  
  // * * * * * * * * * * *
  // SUBMIT ORDER
  useEffect(() => {
    if(submitted) {
      setInProgress(true);
      let paramsOrder = [
        {name: 'json', value: JSON.stringify(props.cart.project)},
        {name: 'process', value: 'add_order_copy'},
        {name: 'organization_id', value: organizationID},
      ];
      
      if(userID && token) {
        paramsOrder.push(
          {name: 'user_id', value: userID},
          {name: 'token', value: token}
        );
      }
      // console.log("JSON CART", JSON.stringify(props.cart.project), props.cart.project);
      props.cartLoadState(paramsOrder, 'orders', 'submit.project_id');
    }
  }, [submitted]);
  
  useEffect(() => {
    if(inProgress && parseInt(props.objGetValue(props.cart, 'submit.project_id')) > 0) {
      const projectID = parseInt(props.objGetValue(props.cart, 'submit.project_id'));
      props.cartRemoveVal('submit');
      setInProgress(false);
      setSubmitted(false);
      clearOrder();
      setRedirect(`/complete/${projectID}`);
    }
  }, [inProgress, props.cart.submit]);
  
  // * * * * * * * * * * * * * * * *
  // NEEDS FINANCIALS
  
  // FUNCTIONS 
  function clearOrder() {
    sessionStorage.removeItem('coreCRT');
    sessionStorage.removeItem('corePrjct');
    props.cartRemoveVal('project');
    props.cartRemoveVal('items');
    props.filesDeleteVal('uploads');
    props.filesDeleteVal('uploadComplete');
  }
  
  
  // console.log("NEW PROJECT MODEL", props.cart.project);
  // console.log("CHECKOUT VERIFIFIED?", verified);
  // console.log("userLocations", userLocations);
  // console.log("TEST", project);
  return (
    <div className="cart">
        {redirect && <Redirect to={redirect} />}
        
        <h2>Checkout</h2>
        
        {project && Array.isArray(project.orders) ?
          <div className="cart-greeting">
            {`Hello${props.objExists(props.user, 'contact.contact_name_first') ? ` ${props.user.contact.contact_name_first}` : ''}, below is your order. Please review before submitting.`}
          </div>
        :
          <div className="cart-greeting">
            <p>You have no items in your cart.</p>
            <p>
              <Link to={props.objExists(props.site, 'preferences.category_home') ? props.site.preferences.category_home : '/categories'}><b>Start Here</b></Link> to add items to your order
            </p>
          </div>
        }
        
        {!userID &&
          <CheckoutAccount {...props} />
        }
        
        {Array.isArray(paymentOptions) && 
          paymentOptions.length > 1 &&
          <CheckoutBilling  {...props}
                              paymentOptions={paymentOptions}
                              billing={props.objGetValue(project, 'billing')}
                              infoEdit={'infoEdit'}
                              formData={'formData'}
                              setPref={'setPref'}
                              location="project" />
        }
        
        {project && 
          Array.isArray(project.orders) && 
          project.orders.length > 0 &&
          project.orders.map((order, index) => {
            return (
              <Fragment>
                {project.orders.length > 1 &&
                  <h3>{`Shipment ${index+1}`}</h3>
                }
                <CheckoutShipping  {...props}
                    userID={userID}
                    token={token}
                    organizationID={organizationID}
                    project={project}
                    order={order}
                    index={index}
                    userLocations={userLocations}
                    languageCode={languageCode}
                    hidePricing={hidePricing}
                    loadAddEdit={'loadAddEdit'}
                    shippingOptions={shippingOptions}
                    shippingRequired={props.objGetValue(shipmentsRequired, `${index}.shipmentRequired`)}
                    restrictLocations={restrictLocations}
                    orderMultiship={orderMultiship}
                    onUpdate={setUpdateProject}
                    errorFlags={errorFlags}
                     />
              </Fragment>
            );
          })
        }
        
        {
          project &&
          <div className="cart-header">
            <ProjectPricing {...props} project={project} pricingLocation="project" hidePricing={hidePricing} />
          </div>
        }
        
        {missingValues &&
          <CartErrorConsole {...props} 
              project={project} 
              missingValues={missingValues}
              actionUpdate={'loadAddEdit'}
              actionErrorFlags={setErrorFlags} />
        }
        
        {project && Array.isArray(project.orders) &&
          <div className="cart-ctl justify-outside">
            <Button 
                    icon="trash" 
                    btnClass="full error-bg" 
                    label="Clear Order" 
                    btnAction={clearOrder}
                    disabled={false} />
            <Button 
                  icon="check" 
                  btnClass="full success-bg" 
                  label="Submit Order" 
                  btnAction={()=>{setSubmitted(true)}}
                  disabled={verified && !submitted ? false : true} />
          </div>
        }
        {inProgress &&
          <Loader label="Submitting order..." isOverlay={true} />
        }
    </div>
  )
}