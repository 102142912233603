import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import FileUpload from 'components/forms/elements/FileUpload';
import EditableLocation from 'components/profiles/EditableLocation';
import UserList from 'components/profiles/users/UserList';
import Control from 'components/atoms/Control';

import ProfileSection from 'components/profiles/profileElements/ProfileSection';

import OrganizationPersona from 'components/profiles/orgs/OrganizationPersona';
import OrganizationSettings from 'components/profiles/orgs/OrganizationSettings';
import OrganizationOrderTasks from 'components/profiles/orgs/OrganizationOrderTasks';
import OrganizationTaskGroups from 'components/profiles/orgs/OrganizationTaskGroups';

import * as forms from 'methods/forms';

export default (props) => {
  const [userAccess, setUserAccess] = useState(false);
  const [logoAdd, setLogoAdd] = useState(false);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [persona, setPersona] = useState(null);
  const [settings, setSettings] = useState(null);
  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [taskList, setTaskList] = useState(null);
  const [taskGroups, setTaskGroups] = useState(null);
  const [taskSettings, setTaskSettings] = useState(null);
  const [staffGroups, setStaffGroups] = useState(null);
  const [orgGroups, setOrgGroups] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  
  const pgParams = useParams();
  const pgLoc = useLocation();
  

  // HOOKS
  useEffect(() => {
    if(pgLoc && pgLoc.search) {
      const pgSearch = props.getURLSearchParams(pgLoc.search);
      if(props.objExists(pgSearch, `section`)) {
        setActiveSection(pgSearch.section);
      }
    }
  }, [pgLoc]);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    if(props.organization && props.organization !== organization) {
      setOrganization(props.organization);
      
      if(props.objExists(props.organization, 'organization_id')) {
        setOrganizationID(props.organization.organization_id);
      }
      
      if(props.objExists(props.organization, 'organization_name')) {
        setOrganizationName(props.organization.organization_name);
      }
    }
  }, [props.organization]);
  
  useEffect(() => {
    if(organizationID && props.coreOrg !==  organizationID && props.actionSelectOrg) {
      props.actionSelectOrg(organizationID);
    }
  }, [organizationID, props.actionSelectOrg])

  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode); 
    }else if(!props.languageCode) {
      setLanguageCode('en');
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.users && props.users !== users) {
      setUsers(props.users);
    }
  }, [props.users]);
  
  useEffect(() => {
    if(props.objGetValue(props, 'persona.organization_id') ===  organizationID &&
        props.persona !== persona) {
        setPersona(props.persona);
    }
  }, [props.persona, organizationID]);
  
  useEffect(() => {
    if(props.objExists(persona, 'settings') &&
      persona.settings !== settings) {
        setSettings(persona.settings);
    }
  }, [persona]);
  
  useEffect(() => {
    props.canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.userAccess && 
        props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    }
    
  }, [props.userAccess])
  
  useEffect(() => {
    if(props.tasks && props.tasks !== tasks) {
      setTasks(props.tasks);
    }
  }, [props.tasks]);
  
  useEffect(() => {
    if(Array.isArray(tasks)) {
      let bTasks = buildTaskList(tasks);
      
      if(bTasks && bTasks !== taskList) {
        setTaskList(bTasks);
      }
    }
  }, [tasks, taskSettings]);
  
  useEffect(() => {
    if(props.taskGroups && props.taskGroups !== taskGroups) {
      setTaskGroups(props.taskGroups);
    }
  }, [props.taskGroups]);
  
  useEffect(() => {
    if(props.taskSettings && Array.isArray(props.taskSettings)) {
      let tSettings = {};
      props.taskSettings.map(setting => {
        tSettings[setting.property_name] = {
            value: setting.property_value === 'true' ? 1 : 0,
            property_id: setting.property_id
        }
      })
      if(tSettings !== taskSettings) {
        setTaskSettings(tSettings);
      }
    }
  }, [props.taskSettings]);
  
  useEffect(() => {
    if(props.staffGroups && 
      props.staffGroups !== staffGroups) {
      setStaffGroups(props.staffGroups);
    }
  }, [props.staffGroups]);
  
  useEffect(() => {
    if(props.orgGroups && 
      props.orgGroups !== orgGroups) {
      setOrgGroups(props.orgGroups);
    }
  }, [props.orgGroups]);

  // FUNCTIONS
  function initAddLocation() {
    let fields = forms.fieldsLocation;
    let fieldCount = 1;

    fields.unshift({
      field_type: 'hidden',
      field_name: 'process',
      field_value: 'add_location',
      field_required: false,
      field_validate: false,
    });

    fields.unshift({
      field_type: 'hidden',
      field_name: 'organization_id',
      field_value: props[props.location ? props.location : 'admin'].organization.organization_id,
      field_required: false,
      field_validate: false,
    });

    Array.isArray(fields) &&
    fields.map(field => {
      fieldCount ++;
      field['field_order'] = fieldCount;
    })

    props.setModal(<FormDynamic
                      content={[{fieldset_name: 'Add Location', fields: fields}]}
                      name="locationUpdate"
                      formActions={{formSubmit: addLocation, formCancel: cancelForm}}
                      closable={true}
                      unmountOnClick={true}
                      cancelable={true}
                      includeControls={true}
                      multiStep={false}
                      returnObject={true}
                      formClass=""
                      valActions={{isEmail: props.isEmail}} />);
  }




  function addLocation(params) {
    params.push({name: 'user_id', value: userID});
    params.push({name: 'token', value: token});
    props.adminAddLinkedObject(params, 'locationToOrganization', organization.organization_id, false);
    props.setModal(null);
  }

  function cancelForm(e) {
    props.setModal(null);
  }
  
  // FUNCTIONS
  function buildTaskList(tasks, parent = null) {
    if(Array.isArray(tasks)) {
      let tList = [];
      tasks.map(task => {
        tList.push({...task, task_parent_name: parent});
        if(props.objExists(task, `order`)) {
          tList.push(
            {
              [languageCode]:{task_name: 'item'},
              type_name: 'admin',
              task_parent_name: 'Admin - Process > Order',
              task_properties: taskSettings,
              task_virtual: true,
              link_type: 'task_admin_item_',
              task_published: 1,
              task_ignored: 0
            }
          );
          if(Array.isArray(task.order['item (creative)']) && 
            task.order['item (creative)'].length > 0) {
            tList.push(...buildTaskList(task.order['item (creative)'], `${task.type_name} - ${task[languageCode].task_name} > Order > Creative Item`));
          }
          if(Array.isArray(task.order['item (inventory)']) && 
            task.order['item (inventory)'].length > 0) {
            tList.push(...buildTaskList(task.order['item (inventory)'], `${task.type_name} - ${task[languageCode].task_name} > Order > Inventory Item`));
          }
          if(Array.isArray(task.order['shipping']) && 
            task.order['shipping'].length > 0) {
            tList.push(
              {
                [languageCode]:{task_name: 'shipping'},
                type_name: 'admin',
                task_parent_name: 'Admin - Process > Order',
                task_properties: taskSettings,
                task_virtual: true,
                link_type: 'task_admin_shipping_',
                task_published: 1,
                task_ignored: 0
              }
            );
            tList.push(...buildTaskList(task.order['shipping'], `${task.type_name} - ${task[languageCode].task_name} > Order > Shipping`));
          }
        }
          
        });
      return tList;
    }else{
      return null;
    }
    
  }
  
  function updateURL(section, value) {
    const sectionName = section.toLowerCase().replace(' ', '-');
    let urlSearchParams = props.getURLSearchParams(window.location.search);
    const pathName = window.location.pathname;
    
    if(value) {
      urlSearchParams['section'] = sectionName;
    }else{
      if(props.objExists(urlSearchParams, 'section')) {
        delete urlSearchParams.section;
      }
    }
    
    window.history.pushState(null, null, `${pathName}${props.paramsToString(urlSearchParams)}`);
  }
  
  return(
    <div className="profile">
      {organization ?
          <Fragment>
            {props.objExists(organization, `organization_name`) && <h2>{organization.organization_name}</h2>}
            <div className="profile-section">
              {props.objExists(organization, `site_identity.media_id`) &&
                Object.entries(organization.site_identity).length > 0 &&
                <div className="profile-media img-identity"
                      style={{backgroundImage: `url(${organization.site_identity.media_file_name ?
                            `http://aecore.co/assets/media/${organization.site_identity.media_file_name}` :
                            `http://aecore.co/assets/media/aeImageUnavailable.jpg`})`}}>
                </div>
              }
              {props.isAdmin && logoAdd &&
                <div class="profile-feature-toggle">
                  <FileUpload {...props} 
                              cueReload={{type: 'organization', location: 'admin'}} 
                              returnInfo={true}
                              media_type='site_identity'
                              media_owner_id={organization.organization_id}
                              media_owner_type ='Organization'
                              media_published ={true}
                              user_id={userID}
                              token={token}
                              organization_id={organizationID}
                              language_code={curLanguage ? curLanguage : languageCode ? languageCode : 'en'} />
                </div>
              }
              <Control icon="add"
                        action={()=>{setLogoAdd(logoAdd ? false : true)}}
                        label={props.objExists(organization, `site_identity.media_file_name`) ? 'Change Logo' : 'Add Logo'}
                        showLabel={true} />
            </div>
            <ProfileSection {...props} label='Locations' expandable={true} onExpand={updateURL} activeSection={activeSection}>
              <Fragment>
                {props.objExists(organization, `locations`) &&
                  Array.isArray(organization.locations) &&
                  organization.locations.length > 0 ?
                    organization.locations.map((location, index) => {
                      return <EditableLocation {...props}
                                                userID={userID}
                                                token={token}
                                                disabled={userAccess ? false : true}
                                                key={`prloc${index}`}
                                                location={location}
                                                locPrefix={`organization.locations[${index}]`}
                                                />})
                  : <p>This organization has no locations</p>
                }
  
                <div className="profile-section-ctl">
                  <Control icon="add"
                            action={initAddLocation}
                            label="Add Location"
                            showLabel={true} />
                </div>
            </Fragment>
            </ProfileSection>

            <ProfileSection {...props} label="Users" expandable={true} onExpand={updateURL} activeSection={activeSection}>
              <Fragment>
                {Array.isArray(users) &&
                  users.length > 0 ?
                    <UserList {...props}
                              allowEdit={true}
                              users={props.users}
                              scroll={true}
                              maxHeight={'20em'} />
                  : <p>This organization has no users.</p>
                }
  
                <div className="profile-section-ctl">
                  <Control icon="add"
                            action={initAddLocation}
                            label="Add User"
                            showLabel={true} />
                </div>
              </Fragment>
            </ProfileSection>
            
            {userAccess && userAccess.super &&
              <ProfileSection {...props} label="Order Tasks" expandable={true} onExpand={updateURL} activeSection={activeSection}>
                <OrganizationOrderTasks 
                        {...props}
                        userID={userID}
                        token={token}
                        languageCode={languageCode}
                        userAccess={userAccess}
                        organization={organization}
                        organizationID={organizationID}
                        taskList={taskList}
                        taskSettings={taskSettings} />
              </ProfileSection>
            }
            
            {userAccess && userAccess.super &&
              <ProfileSection {...props} label="Assigned Groups" expandable={true} onExpand={updateURL} activeSection={activeSection}>
                {props.objExists(props.admin, 'persona') &&
                  <OrganizationTaskGroups 
                        {...props}
                        userID={userID}
                        token={token}
                        languageCode={languageCode}
                        organization={organization}
                        organizationID={organizationID}
                        userAccess={userAccess}
                        taskList={taskList}
                        taskGroups={taskGroups}
                        staffGroups={staffGroups}
                        orgGroups={orgGroups} />}
              </ProfileSection>
            }
            
            {userAccess && userAccess.super &&
              <ProfileSection {...props} label="Core Persona" expandable={true} onExpand={updateURL} activeSection={activeSection}>
                {props.objExists(props.admin, 'persona') &&
                  <OrganizationPersona 
                        {...props}
                        userID={userID}
                        token={token}
                        canEdit={canEdit}
                        userAccess={userAccess}
                        languageCode={languageCode}
                        organization={organization}
                        organizationID={organizationID}
                        persona={persona}
                        staffGroups={staffGroups}
                        orgGroups={orgGroups} />}
              </ProfileSection>
            }
          </Fragment>
        :
        <Loader />
      }

    </div>
  )
}
