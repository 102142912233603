import React, {useEffect, useState, Fragment} from 'react';

import * as forms from 'methods/forms';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import CardValueTag from 'components/molecules/CardValueTag';
import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import OrderList from 'components/profiles/orders/OrderList';
import TaskList from 'components/profiles/tasks/TaskList';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import Control from 'components/atoms/Control';
import FormDynamic from 'components/forms/FormDynamic';
import Loader from 'components/atoms/Loader';
import Button from 'components/forms/elements/Button';
import FormOrgAssign from 'components/forms/common_forms/FormOrgAssign';
import LocationList from 'components/profiles/locations/LocationList';
import FormLocationAddEdit from 'components/forms/common_forms/FormLocationAddEdit';
import FormGroupAssign from 'components/forms/common_forms/FormGroupAssign';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [coreUser, setCoreUser] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [user, setUser] = useState(null);
  const [userOrgs, setUserOrgs] = useState(null);
  const [userOrg, setUserOrg] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [orgGroups, setOrgGroups] = useState(null);
  const [locations, setLocations] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [ordersRequested, setOrdersRequested] = useState(null);
  const [orders, setOrders] = useState(null);
  const [tasksRequested, setTasksRequested] = useState(null);
  const [optionsRoles, setOptionsRoles] = useState(null);
  const [emlStatus, setEmlStatus] = useState(null);
  
  let ivlTasks;
  let ivlProjects;

  useEffect(() => {
    return () => {
      if(ivlTasks) {
        clearInterval(ivlTasks);
      }
      if(ivlProjects) {
        clearInterval(ivlProjects);
      }
      props[`${props.stateLocation}DeleteVal`](props.stateLocation === 'user' ? 'tasks' : 'user.tasks');
      props[`${props.stateLocation}DeleteVal`](props.stateLocation === 'user' ? 'orders' : 'user.orders');
    }
  }, []);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])
  
  useEffect(() => {
    if(props.coreUser && props.coreUser.user_id > 0 !== coreUser) {
      setCoreUser(props.coreUser.user_id);
    }else if(!props.coreUser && userID && userID !== coreUser) {
      setCoreUser(userID);
    }
  }, [props.coreUser, userID]);

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && 
        props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);      
    }else if(!languageCode) {
      setLanguageCode('en');
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    props.userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);

  useEffect(() => {
    if(props.objExists(props.coreUser, 'user_id')) {
      setUser(props.coreUser);
    }
  }, [props.user]);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id') && organizationID) {
      if(props.objGetValue(props.user, `user_roles.${organizationID}.role_id`) === 2 ||
          props.objGetValue(props.user, `user_roles.1.role_id`) === 1) {
          
          setCanEdit(true);  
      }else if(user && user.user_id === userID && !canEdit) {
          setCanEdit(true);
      } else {
        if(canEdit) {
          setCanEdit(false);
        }
      }
    }
  }, [props.user, organizationID, userID, user]);

  useEffect(() => {
    if(user && user.locations) {
      setLocations(user.locations.filter(location => location.location_user_default !== 1));
      setOrganizations(user.organizations);
      setDefaultLocation(user.locations.find(location => location.location_user_default === 1));
    }

    if(user && token && organizationID && coreUser) {
      let orderParams = [
        {name: 'process', value: 'get_projects'},
        {name: 'core_user', value: coreUser},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ]
      
      if(organizationID > 1) {
        orderParams.push({name: 'organization_id', value: organizationID})
      }
      
      if(user &&
        (!user.orders || 
        props.objGetValue(
        props[props.stateLocation], 
        props.stateLocation === 'user' ? 'reload.orders' : 'reload.user.orders') === true)) {
        props[`${props.stateLocation}LoadState`](orderParams, 'orders', props.stateLocation === 'user' ? 'orders' : `user.orders`);
        
        if(props.objGetValue(
          props[props.stateLocation], 
          props.stateLocation === 'user' ? 'reload.tasks' : 'reload.user.tasks') === true) {
          props[`${props.stateLocation}DeleteVal`](props.stateLocation === 'user' ? 'reload.tasks' : 'reload.user.tasks')
        }
        if(ivlProjects) {
          clearInterval(ivlProjects);
        }
        ivlProjects = setInterval(()=>{
          props[`${props.stateLocation}LoadState`](orderParams, 'tasks', props.stateLocation === 'user' ? 'orders' : 'user.orders');
        }, props.minToMS(60));
      }
    }
    
    if(userID && token && organizationID && coreUser) {
      const taskParams = [
        {name: 'process', value: 'get_user_tasks'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'core_user', value: coreUser},
        {name: 'organization_id', value: organizationID},
        {name: 'task_published', value: true},
        {name: 'task_completed', value: false}
      ]
      if(user && 
        (!user.tasks ||
          props.objGetValue(
            props[props.stateLocation], 
            props.stateLocation === 'user' ? 'reload.tasks' : 'reload.user.tasks') === true)) {
          props[`${props.stateLocation}LoadState`](taskParams, 'tasks', props.stateLocation === 'user' ? 'tasks' : 'user.tasks');
          if(props.objGetValue(
            props[props.stateLocation], 
            props.stateLocation === 'user' ? 'reload.tasks' : 'reload.user.tasks') === true) {
            props[`${props.stateLocation}DeleteVal`](props.stateLocation === 'user' ? 'reload.tasks' : 'reload.user.tasks')
          }
          if(ivlTasks) {
            clearInterval(ivlTasks);
          }
          ivlTasks = setInterval(()=>{
            props[`${props.stateLocation}LoadState`](taskParams, 'tasks', props.stateLocation === 'user' ? 'tasks' : 'user.tasks');
          }, props.minToMS(5));
      }
    }
    
    if(props.objExists(user, 'organizations') &&
        Array.isArray(user.organizations) &&
        user.organizations.length > 0) {
        const defaultOrg = user.organizations.find(org => org.organization_default === 1);
        let additionalOrgs;
        if(defaultOrg) {
          setUserOrg({...defaultOrg, user_role: props.objGetValue(user, `user_roles.${defaultOrg.organization_id}`)});
          additionalOrgs = user.organizations.filter(org => org.organization_id !== defaultOrg.organization_id);
        }else{
          setUserOrg(user.organizations[0]);
          additionalOrgs = user.organizations.filter(org => org.organization_id !== user.organizations[0].organization_id);
        }
        additionalOrgs.length > 0 &&
        additionalOrgs.map((aorg, index) => {
          additionalOrgs[index]['user_role'] = props.objGetValue(user, `user_roles.${aorg.organization_id}`);
        })
        setUserOrgs(additionalOrgs);
    } 
  }, [user, userID, token, organizationID, coreUser]);

  useEffect(() => {
    if(user && props.objExists(user, 'orders') &&
        Array.isArray(user.orders) &&
        user.orders.length > 0 ) {
        let userOrders = [];
        if(props.objExists(user, `orders[0].project_id`)) {
          userOrders.push(...user.orders);
        }else if(Array.isArray(props.objGetValue(user, `orders[0].projects`))) {
          user.orders.map((org, index) => {
            if(org.projects && Array.isArray(org.projects)) {
              userOrders.push(...org.projects);  
            }
          })
        }
        userOrders.sort((a, b)=>{return a.project_created > b.project_created ? -1 : 1});
        setOrders(userOrders);
      }
  }, [user && user.orders]);
  
  useEffect(() => {
    if(props.coreUser) {
      setUser(props.coreUser);
    }
  }, [props.coreUser]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'user_roles') &&
      Array.isArray(props.admin.user_roles) && 
      props.admin.user_roles.length > 0) {
        let userRoles = [];
        props.admin.user_roles.map(role => {
          if(props.objGetValue(props.user, 'user_roles.1.role_id') === 1 || role.role_id !== 1) {
            userRoles.push({option_name: role.role_name, option_value: role.role_id});
          }
        })
        
        setOptionsRoles([{options: userRoles}]);
      }
  }, [props.admin.user_roles]);
  
  useEffect(() =>{
    if(props.objGetValue(props.admin, 'email.userInfo') === true) {
      setEmlStatus('sent');
      props.adminDeleteVal('email.userInfo');
    }
  }, [props.admin.email]);
  
  useEffect(() => {
    if(user && Array.isArray(user.user_groups)) {
      let uGroups = {};
      if(parseInt(organizationID) > 1) {
        uGroups[organizationID] = user.user_groups.filter(group => group.group_organization_id === organizationID);
      }else if(Array.isArray(user.organizations)) {
        user.organizations.map((org, index) => {
          uGroups[org.organization_id] = user.user_groups.filter(
            group => group.group_organization_id === org.organization_id);
        }); 
      }
      if(Object.entries(uGroups).length > 0) {
        setUserGroups(uGroups);
      }
      
    }
  }, [user, organizationID]);
  
  useEffect(() => {
    if(props.orgGroups && props.orgGroups !== orgGroups) {
      setOrgGroups(props.orgGroups);
    }
  }, [props.orgGroups]);
  
  // FUNCTIONS
  function loadAddOrganization() {
    props.setModal(
      <FormOrgAssign {...props}
          userID={userID}
          token={token}
          coreUser={user.user_id}
          userName={`${user.contact_name_first} ${user.contact_name_last}`}
          userOrgs={user.organizations}
          organizations={props.admin.organizations ? props.admin.organizations : props.user.organizations}
          userRoles={props.admin.user_roles ? props.admin.user_roles : null}
          actionAssign={addOrganization}
          actionCancel={()=>{props.setModal(null)}} />
          
    )
  }
  
  function addOrganization(params) {
    props.setModal(null);
    props.adminLoadState(params, 'users', 'reload.user');
  }
  
  function loadRemoveFromOrg(org) {
    const removeParams = [
      {name: 'process', value: 'update_link_no_id'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'link_type', value: 'userToOrganization'},
      {name: 'link_from', value: user.user_id},
      {name: 'link_to', value: org.organization_id},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: true}
    ];
    props.setModal(
      <ModalConfirmation {...props}
          icon='group'
          message={`Remove ${user.contact_name_first} from ${org.organization_name}?`}
          actionAccept={()=>{updateLink(removeParams)}} />
    )
  }
  
  
  function loadAddEditLocation(locationID = null) {
    let editLocation;
    if(locationID) {
      editLocation = user.locations.find(loc => loc.location_id === locationID);  
    }
    props.setModal(
      <FormLocationAddEdit {...props}
            userID={userID}
            token={token}
            location={editLocation}
            actionCancel={()=>{props.setModal(null)}}
            actionAddEdit={addEditLocation}
            organizationID={!locationID && userOrg.organization_id ? userOrg.organization_id : null}
            locationType='locationToUser'
            locationLink={!locationID ? user.user_id : locationID}
            returnBool={true}
             />
    );
  }
  
  function addEditLocation(params) {
    props.setModal(null);
    props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](params, 'location', 'reload.user');
  }
  
  function updateUserRole(name, value, params) {
    let updateParams = [
      {name: 'process', value: 'change_user_role'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'core_user', value: user.user_id},
      {name: 'role_id', value: value}
    ];
    if(Array.isArray(params)) {
      updateParams.push(...params);
    }
    props[`${props.location ? props.location : 'admin'}LoadState`](updateParams, 'users', 'reload.user');
  }
  
  function updateUserContact(name, value, optionalParams = []) {
    if(props.objGetValue(user, `contact.${name}`) !== value) {
      let contactParams = [...optionalParams,
        {name: 'process', value: 'update_contact'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: name, value: value}
      ];
      props[`${props.location ? props.location : 'admin'}LoadState`](contactParams, 'contacts', 'reload.user');  
    }
  }
  
  function loadAssignGroup(org) {
    props.setModal(
      <FormGroupAssign
        {...props}
        userID={userID}
        token={token}
        groupType='userToGroup'
        groupLink={user.user_id}
        groups={orgGroups[org]}
        actionSubmit={assignGroup}
        />
    )
  }
  
  function assignGroup(params) {
    props.setModal(null);
    const asnGroup = params.find(param => param.name === 'link_to');
    if(user.user_groups.find(grp => grp.group_id === parseInt(asnGroup.value))) {
      props.notifyUser(
        {message: `${user.contact_name_first} is already in that group`,
        icon: 'group'}
      );
    }else{
      props[`${props.location ? props.location : 'admin'}LoadState`](params, 'links', 'reload.user');  
    }
  }
  
  function loadUnassignGroup(group, org) {
    const unassignParams = [
      {name: 'process', value: 'update_link_no_id'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'link_type', value: 'userToGroup'},
      {name: 'link_from', value: user.user_id},
      {name: 'link_to', value: group.group_id},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: true}
    ];
    props.setModal(
      <ModalConfirmation {...props}
          icon='group'
          message={`Remove ${user.contact_name_first} from ${group.group_name} for ${org}?`}
          actionAccept={()=>{updateLink(unassignParams)}} />
    )
  }
  
  function updateLink(params) {
    props.setModal(null);
    props[`${props.location ? props.location : 'admin'}LoadState`](params, 'links', 'reload.user');
  }
  
  function sendEml() {
    const params = [
      {name: 'process', value: 'eml_send_user_new'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'core_user', value: user.user_id},
      {name: 'organization_id', 
        value: props.objExists(user, 'organizations') &&
                Array.isArray(user.organizations) && 
                user.organizations.length > 0 ?
                  user.organizations.find(org => org.organizagtion_default === 1) ?
                    user.organizations.find(org => org.organizagtion_default === 1).organization_id :
                        user.organizations[0].organization_id :
                        organizationID
                
      },
    ]
    
    props.adminLoadState(params, 'users', `email.userInfo`);
    setEmlStatus('sending');
  }
  
  function reloadTasks() {
    if(props.stateLocation === 'user') {
      props.userSetVal('reload.tasks', true);  
    }else{
      props.adminSetVal('reload.user.tasks', true);
    }
  }

  return (
    <article className="user">
      <h2><IconSVG icon="user" className="h2" />User Account</h2>
      {user ?
        <Fragment>
          <div className="flex-row">
            <div className="flex-column">
              <Card>
                {canEdit &&
                  organizationID ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    className="heading"
                                    name='combo'
                                    label={null}
                                    value={[
                                      {name: 'contact_name_first', 
                                      value: props.objExists(user, 'contact.contact_name_first') ? user.contact.contact_name_first : null, 
                                      label: 'First Name'},
                                      {name: 'contact_name_last', 
                                      value: props.objExists(user, 'contact.contact_name_last')  ? user.contact.contact_name_last : null, 
                                      label: 'Last Name'}
                                    ]}
                                    type='combo'
                                    optionalParams={[
                                      {name: 'contact_id', value: props.objExists(user, 'contact.contact_id') ? user.contact.contact_id : null}
                                    ]}
                                    action={updateUserContact} />
                  </div>
                :  
                  <div className="card-row heading">
                    <span className="card-row-field">
                      {`${props.objExists(user, `contact.contact_name_first`) ?  
                          user.contact.contact_name_first : ''} ${props.objExists(user, `contact.contact_name_last`) ?
                            user.contact.contact_name_last : ''}`
                      }
                    </span>
                  </div>
                }
                {userOrg && props.objExists(userOrg, 'organization_name') &&
                  <div className="card-row">
                    <label>Organization</label>
                    <span className="card-row-field">{userOrg.organization_name}</span>
                  </div>
                }
                {props.isAdmin &&
                  canEdit &&
                  organizationID &&
                  optionsRoles ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    name='role_id'
                                    label="User Role"
                                    value={props.objGetValue(userOrg, `user_role.role_id`)}
                                    type='select'
                                    option_groups={optionsRoles}
                                    action={updateUserRole}
                                    optionalParams={[
                                      {name: 'organization_id', value: userOrg.organization_id}
                                    ]} />
                  </div>
                :  
                  <div className="card-row">
                    <label>Role</label>
                    <span className="card-row-field">
                      {props.objExists(user, `user_roles.${userOrg && userOrg.organization_id ? userOrg.organization_id : organizationID}.role_name`) ? 
                      user.user_roles[userOrg && userOrg.organization_id ? userOrg.organization_id : organizationID].role_name : 'No Role'}
                    </span>
                  </div>
                }
                {canEdit &&
                  organizationID ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    name='contact_email'
                                    label="Email"
                                    value={props.objExists(user, 'contact.contact_email') ? user.contact.contact_email : ''}
                                    type='text'
                                    optionalParams={[
                                      {name: 'contact_id', value: props.objExists(user, 'contact.contact_id') ? user.contact.contact_id : null}
                                    ]}
                                    action={updateUserContact} />
                  </div>
                :  
                  <div className="card-row">
                    <label>Email</label>
                    <span className="card-row-field">
                      {props.objExists(user, `contact.contact_email`) ? user.contact.contact_email : ''}
                    </span>
                  </div>
                }
                {canEdit &&
                  organizationID ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    name='contact_phone'
                                    label="Phone"
                                    value={props.objExists(user, 'contact.contact_phone') ? user.contact.contact_phone : null}
                                    type='text'
                                    optionalParams={[
                                      {name: 'contact_id', value: props.objExists(user, 'contact.contact_id') ? user.contact.contact_id : null}
                                    ]}
                                    action={updateUserContact} />
                  </div>
                :  
                  props.objExists(user, `contact.contact_phone`) && 
                  <div className="card-row">
                    <label>Phone</label>
                    <span className="card-row-field">
                      {user.contact.contact_phone}
                    </span>
                  </div>
                }
                {canEdit &&
                  organizationID ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    name='contact_phone_ext'
                                    label="Phone Ext"
                                    value={props.objExists(user, 'contact.contact_phone_ext') ? user.contact.contact_phone_ext : null}
                                    type='text'
                                    optionalParams={[
                                      {name: 'contact_id', value: props.objExists(user, 'contact.contact_id') ? user.contact.contact_id : null}
                                    ]}
                                    action={updateUserContact} />
                  </div>
                :  
                  props.objExists(user, `contact.contact_phone_ext`) && 
                  <div className="card-row">
                    <label>Phone Ext</label>
                    <span className="card-row-field">
                      {user.contact.contact_phon_ext}
                    </span>
                  </div>
                }
                {canEdit &&
                  organizationID ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    name='contact_fax'
                                    label="Fax"
                                    value={props.objExists(user, 'contact.contact_fax') ? user.contact.contact_fax : null}
                                    type='text'
                                    optionalParams={[
                                      {name: 'contact_id', value: props.objExists(user, 'contact.contact_id') ? user.contact.contact_id : null}
                                    ]}
                                    action={updateUserContact} />
                  </div>
                : 
                  props.objExists(user, `contact.contact_fax`) && 
                  <div className="card-row">
                    <label>Fax</label>
                    <span className="card-row-field">
                      {user.contact.contact_fax}
                    </span>
                  </div>
                }
                {canEdit &&
                  organizationID ?
                  <div className="card-row">
                    <LiveFieldInput {...props}
                                    name='contact_cell'
                                    label="Mobile"
                                    value={props.objExists(user, 'contact.contact_cell') ? user.contact.contact_cell : null}
                                    type='text'
                                    optionalParams={[
                                      {name: 'contact_id', value: props.objExists(user, 'contact.contact_id') ? user.contact.contact_id : null}
                                    ]}
                                    action={updateUserContact} />
                  </div>
                :  
                  props.objExists(user, `contact.contact_cell`) &&
                  <div className="card-row">
                    <label>Cell</label>
                    <span className="card-row-field">
                      {user.contact.contact_cell}
                    </span>
                  </div>
                }
               
                {canEdit && 
                  user.user_key_reset === 1 &&
                  <div className="form-row">
                    {emlStatus !== 'sending' ?
                      <Button icon="email" 
                              label={emlStatus === 'sent' ? 'Resend User Info' : 'Send User Info'}
                              btnAction={sendEml}
                              btnClass="info-bg mini" />
                    :
                      <Loader className="mini" label="Sending..." /> 
                    }
                  </div>
                }
              </Card>
              
              {userAccess && (userAccess.super || userOrgs) &&
                <Card title="Additional Organizations">
                  {userOrgs && 
                    Array.isArray(userOrgs) && 
                    userOrgs.length > 0 &&
                    <div className="grid cells" style={{marginRight: '2em'}}>
                      <div className="grid-header">Organization</div>
                      <div className="grid-header">Role</div>
                      {optionsRoles &&
                        userOrgs.map((org, index) => {
                          return (
                            <Fragment>
                              <div className="column name align-left card-row"  style={{marginBottom: '0em'}}>
                                <div>{org.organization_name}</div>
                                {userAccess && (userAccess.super || userAccess.staffAdmin) &&
                                  <div className="ctl auto">
                                    <IconToken {...props}
                                        icon="close"
                                        action={()=>{loadRemoveFromOrg(org)}}
                                        tooltip="Remove" />
                                  </div>
                                }
                              </div>
                              <div className="column card-row" style={{marginBottom: '0em'}}>
                                <LiveFieldInput {...props}
                                                    name='role_id'
                                                    label={null}
                                                    value={props.objGetValue(org, `user_role.role_id`)}
                                                    type='select'
                                                    option_groups={optionsRoles}
                                                    action={updateUserRole}
                                                    disabled={userAccess && (userAccess.super || userAccess.staffAdmin) ? false : true}
                                                    optionalParams={[
                                                      {name: 'organization_id', value: org.organization_id}
                                                    ]} />
                              </div>
                            </Fragment>  
                          )
                        })
                      }
                    </div>
                  }
                  {userAccess && (userAccess.super || userAccess.staffAdmin) &&
                    <Control icon="add" label="Add Organization" showLabel={true} action={loadAddOrganization}/>
                  }
                </Card>
              }
              
              {props.isAdmin && userAccess && (userAccess.super || userOrgs) &&
                <Card title="User Groups">
                  {userGroups &&
                    <div className="grid cells" style={{marginRight: '2em'}}>
                      <div className="grid-header">Organization</div>
                      <div className="grid-header">Group(s)</div>
                      {
                        Object.keys(userGroups).map((gKey, index) => {
                          if(Array.isArray(userGroups[gKey]) && 
                              userGroups[gKey].length > 0) {
                            return (
                              <Fragment>
                                <div className="column name">{userGroups[gKey][0].group_organization}</div>
                                <div className="column">
                                  <div className="card-block">
                                    {userGroups[gKey].map((group, index) => {
                                      if(organizationID === 1 || group.group_organization_id === organizationID) {
                                        return (
                                            <div className="card-row justify-outside">
                                              <div>{props.capitalizeString(group.group_name, true)}</div>
                                              {userAccess && (userAccess.super || userAccess.staffAdmin) &&
                                                <div className="ctl auto">
                                                  <IconToken {...props}
                                                      icon="close"
                                                      action={()=>{loadUnassignGroup(group, group.group_organization)}}
                                                      tooltip="Unassign" />
                                                </div>
                                              }
                                            </div>
                                            
                                        )
                                      }
                                    })}
                                  </div>
                                  {userAccess && (userAccess.super || userAccess.staffAdmin) &&
                                  <Control icon="group"
                                          label="Assign to additional groups" 
                                          showLabel={true}
                                          action={()=>{loadAssignGroup(userGroups[gKey][0].group_organization)}}
                                           />
                                  }
                                </div>
                              </Fragment>
                            )
                          }
                        })
                      }
                    </div>
                  }
                
                </Card>  
              }
              
              <Card title="Default Address">
                <div className="card-block">
                  {defaultLocation && defaultLocation.location_name &&
                      <p><b>{defaultLocation.location_name}</b></p>
                  }
                  {defaultLocation ?
                    <Fragment>
                      <p>{defaultLocation.location_address_1}{defaultLocation.location_address_2 ? `, ${defaultLocation.location_address_2}` : ''}</p>
                      <p>{defaultLocation.location_city}, {defaultLocation.location_province}</p>
                      <p>{defaultLocation.location_country}  {defaultLocation.location_postal_code}</p>
                    </Fragment>
                  :
                    <p>No default address</p>
                  }
                  <div className="card-row ctl">
                    <IconToken icon="edit"
                                tooltip="Edit" 
                                action={()=>{loadAddEditLocation(defaultLocation.location_id)}} />
                  </div>
                  {(!locations || !Array.isArray(locations) || locations.length < 1) && 
                    <Control icon="add" label="Add Location" showLabel={true} action={()=>{loadAddEditLocation(null)}}/>
                  }
                </div>
              </Card>
              {locations &&
                Array.isArray(locations) &&
                locations.length > 0 &&
                <Card title="Additional Locations">
                  <div className="card-list">
                    <LocationList {...props}
                        userID={userID}
                        token={token}
                        organizationID={organizationID}
                        languageCode={languageCode}
                        locations={locations}
                        locationType='locationToUser'
                        locationTypeID={user.user_id}
                        stateLocation={props.stateLocation}
                        listLocation='user'
                        omitDefault={true} />
                  </div>
                  <Control icon="add" label="Add Location" showLabel={true} action={()=>{loadAddEditLocation(null)}}/>
                </Card>
              }
            </div>
            
            

            <div className="flex-column">
              {orders && Array.isArray(orders) &&
                orders.length > 0 &&
                    <OrderList {...props}
                              userID={userID}
                              token={token}
                              coreUser={coreUser}
                              organizationID={organizationID}
                              languageCode={languageCode} 
                              orders={orders}
                              location='user' />
              }

              {props.objExists(user, 'tasks')
                && Array.isArray(user.tasks)
                && user.tasks.length > 0 &&
                <TaskList {...props}
                          userID={userID}
                          token={token}
                          languageCode={languageCode}
                          userAccess={userAccess}
                          tasks={user.tasks}
                          location="user"
                          actionReload={reloadTasks}/>
              }

              {props.objExists(user, 'messages')
                && Array.isArray(user.messages)
                && user.messages.length > 0 &&
                  <Card title="Messages">

                  </Card>
              }
            </div>
          </div>
        </Fragment>
      :
        <Loader label="Loading user..." />
      }
    </article>
  )
}
