import React, {useState, useEffect} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

import * as forms from 'methods/forms';

export default(props) => {
  const [form, setForm] = useState(null);
  const [index, setIndex] = useState(null);
  const [formContent, setFormContent] = useState(null);

  useEffect(() => {
    console.log("field count", props.field_count);
    if(props.field_count && parseInt(props.field_count) > 0) {
      setIndex(parseInt(props.field_count) + 1);
    }else{
      setIndex(1);
    }
  }, [props.field_count]);

  // FUNCTIONS
  function fieldOptions(e) {
    console.log("something about options");
  }

  function handleCancel() {
    props.setModal(null);
  }

  function handleAdd(params) {
    if(props.option_id) {
      params.push({name: 'fieldset_type', value: 'Option'});
      params.push({name: 'fieldset_link', value: props.option_id});
    }else{
      params.push({name: 'fieldset_type', value: 'Product'});
      params.push({name: 'fieldset_link', value: props.product_id});
    }
    if(props.actionAdd) {
      props.actionAdd(params);
    }
  }

  function handleChange(e) {
    console.log("field change");
  }

  useEffect(() => {
    if(index) {
      setFormContent(
        [
          { fieldset_name: `${props.objExists(props, 'field.field_id') ? 'Edit' : 'Add'} Custom Info Field`,
            fields: [
            {
              field_type: 'switch',
              field_name: `field_published`,
              field_label: 'Published',
              field_value: props.objExists(props, 'field.field_published') && !props.objGetValue(props, 'field.field_published') ? false : true,
              field_required: false,
              field_validate: false,
              field_order: 1,
            },
            {
              field_type: 'switch',
              field_name: `field_required`,
              field_label: 'Required',
              field_value: props.objGetValue(props, 'field.field_required') ? true : false,
              field_required: false,
              field_validate: false,
              field_order: 1,
            },
            {
              field_type: 'text',
              field_name: `field_label`,
              field_label: 'Field Name (Label)',
              field_value: props.objGetValue(props, 'field.field_label'),
              field_required: true,
              field_validate: true,
              field_order: 2,
            },
            {
              field_type: 'number',
              field_name: `field_order`,
              field_label: 'Field Position (Order)',
              field_value: props.objExists(props, 'field.field_order') ? props.field.field_order : index,
              field_required: true,
              field_validate: true,
              field_order: 3,
            },
            {
              field_type: 'select',
              field_name: `field_type`,
              field_label: 'Field Type',
              field_placeholder: 'Select type...',
              field_value: props.objGetValue(props, 'field.field_type'),
              field_required: true,
              field_validate: true,
              field_order: 5,
              fieldOnChange: fieldOptions,
              option_groups: [{
                options: [
                  {option_name: 'Text Input', option_value: 'text',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                      {
                        field_type: 'text',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: false,
                        field_validate: false,
                        field_order: 4,
                        option_groups: [{options: forms.optionsSides}]
                      },
                    ]}]
                  },
                  {option_name: 'Email Input', option_value: 'email',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                      {
                        field_type: 'email',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: false,
                        field_validate: false,
                        field_order: 4,
                        option_groups: [{options: forms.optionsSides}]
                      },
                      
                    ]}]
                  },
                  {option_name: 'Phone Input', option_value: 'tel',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: '',
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                      {
                        field_type: 'phone',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: false,
                        field_validate: false,
                        field_order: 4,
                        option_groups: [{options: forms.optionsSides}]
                      },
                      
                    ]}]
                  },
                  {option_name: 'Number Input (000)', option_value: 'number',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                      {
                        field_type: 'number',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: false,
                        field_validate: false,
                        field_order: 4,
                        option_groups: [{options: forms.optionsSides}]
                      },
                    ]}]
                  },
                  {option_name: 'Float Input (0.0)', option_value: 'float',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                      {
                        field_type: 'float',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: false,
                        field_validate: false,
                        field_order: 4,
                        option_groups: [{options: forms.optionsSides}]
                      },
                    ]}]
                  },
                  {option_name: 'Price Input (0.00)', option_value: 'price',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                      {
                        field_type: 'price',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: false,
                        field_validate: false,
                        field_order: 4,
                        option_groups: [{options: forms.optionsSides}]
                      },
                    ]}]
                  },
                  {option_name: 'File Upload', option_value: 'file',
                    fieldsets: [{fields: [
                     {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: `Placeholder Text`,
                        field_value: props.objGetValue(props, `field.field_placeholder`),
                        field_required: false,
                        field_validate: true,
                        field_order: 6
                     },
                    ]}]
                  },
                  {option_name: 'Date', option_value: 'date',
                  fieldsets: [{fields: [
                      {field_type: 'select',
                      field_name: 'date_minimum_type',
                      field_value: props.objGetValue(props, `field.date_minimum_type`),
                      field_required: false,
                      field_validate: false,
                      field_order: 6,
                      option_groups: [
                        {options: [
                          {option_name: 'No Minimum', option_value: null},
                          {option_name: 'Full Date', option_value: 'full_date',
                            fieldsets: [{fields: [
                              {
                                field_type: 'date',
                                field_name: 'field_placeholder',
                                field_label: 'Minimum Selectable Date',
                                field_value: props.objGetValue(props, `field.field_placeholder`),
                                field_required: false,
                                field_validate: false,
                                field_order: 7
                              },
                            ]}]
                          },
                          {option_name: 'After (X) Days', option_value: 'days',
                            fieldsets: [{fields: [
                              {
                                field_type: 'number',
                                field_name: 'field_placeholder',
                                field_label: 'Number of Days',
                                field_placeholder: 'Number of days only',
                                field_value: props.objGetValue(props, `field.field_placeholder`),
                                field_required: false,
                                field_validate: false,
                                field_order: 7
                              },
                            ]}]
                          },
                        ]
                          
                        }
                      ]
                        
                      },
                    ]}]
                  },
                  {option_name: 'Password', option_value: 'password',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                    ]}]
                  },
                  {option_name: 'Select Menu', option_value: 'select',
                    fieldsets: [{fields: [
                      {field_type: 'message',
                        field_value: "You will can add or edit options once the field is created",
                      }
                    ]}]
                  },
                  {option_name: 'Multiple Value', option_value: 'multi',
                    fieldsets: [{fields: [
                      {field_type: 'message',
                        field_value: "You will can add or edit options once the field is created",
                      }
                    ]}]
                  },
                  {option_name: 'Live Switch (on/off)', option_value: 'switch'},
                  {option_name: 'Text Block', option_value: 'textarea',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: true,
                        field_order: 6,
                      },
                    ]}]
                  },
                  {option_name: 'Hidden', option_value: 'hidden',
                    fieldsets: [{fields: [
                      {
                        field_type: 'text',
                        field_name: `field_placeholder`,
                        field_label: 'Placeholder Text',
                        field_value: props.objGetValue(props, 'field.field_placeholder'),
                        field_required: false,
                        field_validate: false,
                        field_order: 6,
                      },
                      {
                        field_type: 'text',
                        field_name: `field_default`,
                        field_label: 'Default Value',
                        field_value: props.objGetValue(props, 'field.field_default'),
                        field_required: true,
                        field_validate: false,
                        field_order: 6,
                        option_groups: [{options: forms.optionsSides}]
                      },
                      
                    ]}]
                  },
                ]
            }]
          },
          {field_type: 'hidden',
            field_name: 'process',
            field_value: props.objExists(props, 'field.field_id') ? 'update_fieldset_field' : 'create_field'
          },
          props.objExists(props, 'field.field_id') &&
          {field_type: 'hidden',
            field_name: 'field_id',
            field_value: parseInt(props.field.field_id)
          },
          props.objExists(props, 'field.text_id') &&
          {field_type: 'hidden',
            field_name: 'text_id',
            field_value: parseInt(props.field.text_id)
          },
          props.objExists(props, 'fieldset_id') &&
          {field_type: 'hidden',
            field_name: 'fieldset_id',
            field_value: parseInt(props.fieldset_id)
          },
          props.objExists(props, 'option_id') &&
          {field_type: 'hidden',
            field_name: 'option_id',
            field_value: parseInt(props.option_id)
          },
        ]
      }]
    )
    }
  }, [index]);

  useEffect(() => {
    if(formContent) {
      setForm(
        <FormDynamic {...props}
                      content={formContent}
                      name="add-add-field"
                      returnObject={true}
                      fieldOnChange={handleChange}
                      formActions={{formCancel: handleCancel, formSubmit: handleAdd}} />

      )
    }
  }, [formContent]);

  return form;
}
