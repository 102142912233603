import React, {useEffect, useState, useRef} from 'react';

import * as utils from 'methods/site';

import IconToken from 'components/icons/IconToken';


export default (props) => {
  const [value, setValue] = useState("");
  const [fieldName, setFieldName] = useState(null);
  const [reveal, setReveal] = useState(false);

  const inputRef = useRef();

  // HOOKS
  useEffect(() => {
    return () => {
      setValue(null);
    }
  }, []);
  
  useEffect(() => {
    if(props.value || props.value >= 0) {
      setValue(props.value);
    }else if(props.defaultValue || props.defaultValue >= 0) {
      setValue(props.defaultValue);
    }else{
      setValue('');
    }
  }, [props.value, props.defaultValue]);
  
  useEffect(() => {
    if(props.reveal) {
      setReveal(props.reveal);
    }
  }, [props.reveal]);

  // useEffect(() => {
  //   if(props.value !== null && value !== props.value) {
  //     setValue(props.value);
  //   }else if(!props.value && props.defaultValue !== null &&
  //           value !== props.defaultValue) {
  //     setValue(props.defaultValue);
  //   }
  //   
  // }, [props.value, props.defaultValue]);

  useEffect(() => {
    if(props.name !== fieldName && inputRef.current) {
      // REMOVED THE FOLLOWING 2 LINES DUE TO ISSUE WITH LIVE UPDATE Field
      // LEAVING IN CASE ISSUES HAPPEN IN OTHER USES
      // inputRef.current.value = props.defaultValue ? props.defaultValue : null;
      // setValue(props.defaultValue ? props.defaultValue : null);

      let inputContainer = inputRef.current;
      while(!inputContainer.classList.contains('input') && inputContainer.tagName.toLowerCase() !== 'body') {
        inputContainer = inputContainer.parentNode;
      }
      inputContainer.classList.contains('valid') &&
      inputContainer.classList.remove('valid');
    }
    setFieldName(props.name);
  }, [props.name]);

  function handleKey(e) {
    // this checks for forward slashes or single quotes
    if(document.activeElement.type === 'input' && (e.keyCode === 191 || e.keyCode === 222)) {
      e.preventDefault();
      setValue(`${this.state.inputValue}${e.key}`)
      document.activeElement.focus();
    }
  }

  function focusInput(e) {
    e.persist();
    if(e.target.tagName.toLowerCase === 'div') {
      e.target.getElementsByTagName('input')[0].focus();
    }else if (e.target.tagName.toLowerCase === 'label'){
      e.target.nextSibling.focus();
    }
  }

  function handleChange(e) {
    e.persist();
    let val = e.target ? e.target.value : e.value;
    let cleanVal;
    if(props.type === 'tel' || props.type === 'phone') {
      cleanVal = utils.phoneDisplay(val);
    }else if(props.type === 'number'){
      cleanVal = utils.numberInput(val, props.isDecimal ? true : false);
    }else if(props.type === 'float') {
      cleanVal = utils.numberInput(val, true);
    }else if(props.type === 'price') {
      cleanVal = utils.numberInput(val, true, 2);
    }else if(props.type === 'email') {
      cleanVal = val.toLowerCase()
    }else if(props.type === 'cc-number') {
      cleanVal = utils.creditCardNumberInput(val);
    }else if(props.type === 'cc-expiry') {
      cleanVal = utils.creditCardExpiryInput(val);
    }else if(props.type === 'cc-cvv') {
      cleanVal = utils.creditCardCVVInput(val);
    }else{
      cleanVal = val;
    }
    
    setValue(cleanVal);
    
    if(props.updateAction && Array.isArray(props.updateAction)) {
      setTimeout(()=>{
        props.updateAction.map(action => {
           return action({name: e.target ? e.target.name : e.name, value: cleanVal});
        })
      }, 100);
    } else if (props.updateAction){
      setTimeout(()=>{props.updateAction({name: e.target ? e.target.name : e.name, value: cleanVal})}, 100);
    }
    props.onChange && 
    props.onChange({name: e.target ? e.target.name : e.name, value: cleanVal});
  }

  function handleFocus(e) {
    e.persist();
    if(props.focusSelect) {
      e.target.select();
    }

    props.onFocus &&
    props.onFocus(e);
  }

  let placeholder = props.placeholder ? props.placeholder : props.label;
  if(props.required) {
    placeholder += '*';
  }

  useEffect(() => {
    if((props.defaultValue || props.value) && props.onLoad) {
      props.onLoad(props.name, props.value ? props.value : props.defaultValue ? props.defaultValue : null);
    }
  }, []);

  useEffect(() => {
    props.defaultValue &&
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    if(props.focusSelect && props.focusSelect === true) {

    }
  }, [props.focusSelect]);
  
  // ADDED 04132021 NEEDS FULL TESTING ACROSS APP
  useEffect(() => {
    if(props.onChange) {
      props.onChange({name: props.name, value: value});
    }
  }, [value]);
  
  useEffect(() => {
    if(props.clearValue && value && value !== '') {
      setValue('');
    }
  }, [props.clearValue])
  
  return (
    <div className={`input${props.isMissing || props.errormsg ? ' form-input-missing' : ''}
                      ${props.className ? ` ${props.className}` : ''}`}
        onClick={focusInput}>
    {props.label &&
      <label htmlFor={props.id? props.id : null} className="input-label" >
        {value || value === 0 || props.errormsg ? props.label : ` `}
        {(value || value === 0 || props.errormsg) && props.required ? <span className="flag-required">&#10035;</span> : ''}
        {props.errormsg && <div className="form-input-error-msg">! {props.errormsg}</div>}
      </label>
    }
    <input
      name={props.name? props.name : null}
      id={props.id? props.id : null}
      className="input-field"
      onChange={handleChange}
      type={props.type === 'phone' ? 'tel' :
              props.type && props.type === 'password' ?
                reveal ? 'text' : 'password'
                : 'text'}
      value={value}
      placeholder={placeholder}
      required={props.required ? true : false}
      onFocus={handleFocus}
      onBlur={props.onBlur}
      tabIndex={props.tabIndex}
      ref={props.inputRef ? props.inputRef : inputRef}
      autoFocus={props.focus ? true : false}
      disabled={props.disabled === true ? true : false}
      />
      {props.valid && <IconToken icon="check" className="success field-indicator" />}
      {props.type === 'password' &&
      <IconToken icon={reveal ? 'lock' : 'eye'} className="input-field-icon" action={()=>{reveal ? setReveal(false) : setReveal(true)}} />
      }
      
      {props.type !== 'password' && props.icon && !props.iconLeft &&
      <IconToken icon={props.icon} className="input-field-icon" />
      }
    </div>
  )
}
