import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import ProjectStatus from  'components/profiles/projects/ProjectStatus';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [orderCount, setOrderCount] = useState(null);
  const [locationCount, setLocationCount] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [rushCount, setRushCount] = useState(0);
  const [redirect, setRedirect] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const [projectContact, setProjectContact] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [isCancelled, setIsCancelled] = useState(null);
  const [isComplete, setIsComplete] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  
  useEffect(() => {
    return()=>{
      setInProgress(false);
      setIsCancelled(false);
      setIsComplete(false);
      setTaskStatus(null);
      setProjectStatus(null);
    }
  }, []);
  

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);

  useEffect(() => {
    inProgress && setInProgress(false);
    isCancelled && setIsCancelled(false);
    isComplete && setIsComplete(false);
    taskStatus && setTaskStatus(false);
    projectStatus && setProjectStatus(null);
    
    
    props.project.project_order_count &&
    setOrderCount(props.project.project_order_count);
    
    props.project.project_location_count &&
    setLocationCount(props.project.project_location_count);

    props.project.project_item_count &&
    setItemCount(props.project.project_item_count);
    
    if(props.objExists(props.project, 'current_task.task_name')) {
      setTaskStatus(props.project.current_task.task_name);
    }
    
    if(props.objExists(props.project, 'user.contact_name_first')) {
      setProjectContact(props.project.user);
    }else if(props.objExists(props.project, 'contact.contact_name_first')) {
      setProjectContact({...props.project.contact, ...props.project.location ? props.project.location : {}});
    }
    
    if(props.objExists(props.project, `status[0].${languageCode}.status_name`)) {
      setProjectStatus(props.objGetValue(props.project, `status[0].${languageCode}.status_name`));
    }
  }, [props.project, languageCode]);
  
  useEffect(() => {
    if(projectStatus) {
       
      if(projectStatus === 'cancelled') {
        setIsCancelled(true);
      }else if(projectStatus === 'complete' ||
              projectStatus === 'archived') {
        setIsComplete(true);
      }
    }
  }, [projectStatus]);
  
  // FUNCTIONS
  function loadCompleteOrder(removeOrder = false) {
    props.setModal(
      <ModalConfirmation {...props}
                        message={`Complete order ${props.projectNumDisplay(props.project.project_id, props.project.organization_code)}? This will end any active tasks within the order.`}
                        actionAccept={completeOrder} />
    )
  }
  // deactivateProject($user_id = 0, $token = null, $project_id = 0, $status = null)
  function completeOrder(removeOrder = false) {
    props.setModal(null);
    const completeParams = [
      {name: 'process', value: 'deactivate_project'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'project_id', value: props.project.project_id},
      {name: 'status', value: 'completed'}
    ]
    props.adminLoadState(completeParams, 'orders', 'reload.orders');
    setInProgress("Completing order...");
  }
  
  function loadCancelOrder(removeOrder = false) {
    console.log("REMOVE ORDER WITH CANCEL?", removeOrder);
    props.setModal(
      <ModalConfirmation {...props}
                        message={`Are you sure you want to ${removeOrder ? 'remove' : 'cancelling'} Order ${props.projectNumDisplay(props.project.project_id, props.project.organization_code)}?${
                            removeOrder ? ' The order will be deleted from the list.' : ''
                        }`}
                        actionAccept={cancelOrder} />
    )
  }
  
  function cancelOrder() {
    // cancelProject($user_id = 0, $token = null, $project_id = 0, $organization_id = 0, $remove_project = false, $cancel_message = null, $language_code = "en")
    props.setModal(null);
    const cancelParams = [
      {name: 'process', value: 'cancel_project'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'project_id', value: props.project.project_id},
      {name: 'organization_id', value: props.project.organization_id},
      {name: 'remove_project', value: false},
      {name: 'language_code', value: languageCode}
    ] 
    props.adminLoadState(cancelParams, 'orders', 'reload.orders');
    setInProgress("Cancelling order...");
  }
  
  function loadReactivateOrder() {
    props.setModal(
      <ModalConfirmation {...props}
                        message={`Reactivate Order ${props.projectNumDisplay(props.project.project_id, props.project.organization_code)}? This will restore the order and reactivate any active tasks`}
                        actionAccept={reactivateOrder} />
    )
  }

  function reactivateOrder() {
    props.setModal(null);
    const reactivateParams = [
      {name: 'process', value: 'reactivate_project'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'project_id', value: props.project.project_id}
    ]
    
    console.log("REACTIVATING ORDER - ", reactivateParams);
    props.adminLoadState(reactivateParams, 'orders', 'reload.orders');
  }

  function loadArchiveOrder() {
    props.setModal(
      <ModalConfirmation {...props}
                        message={`Archive Order ${props.projectNumDisplay(props.project.project_id, props.project.organization_code)}? This will remove it from the active order list`}
                        actionAccept={archiveOrder} />
    )
  }
  
  function archiveOrder(removeOrder = false) {
    props.setModal(null);
    const archiveParams = [
      {name: 'process', value: 'archive_project'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'project_id', value: props.project.project_id}
    ]
    setInProgress("Archiving order...");
    props[`${props.location ? props.location : 'admin'}LoadState`](archiveParams, 'orders', 'reload.orders');
  }
  
  function loadRemoveOrder() {
    props.setModal(
      <ModalConfirmation {...props}
                        message={`Remove order ${props.projectNumDisplay(props.project.project_id, props.project.organization_code)}?  The order will be deleted.`}
                        actionAccept={removeOrder} />
    )
  }
  
  function removeOrder() {
    props.setModal(null);
    const removeParams = [
      {name: 'process', value: 'deactivate_project'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'project_id', value: props.project.project_id},
      {name: 'status', value: 'removed'}
    ];
    props.adminLoadState(removeParams, 'orders', 'reload.orders');
    setInProgress("Removing order...");
  }
  
  console.log("props.project", props.project);
  
  return (
    <div className={`list-item project${isCancelled ? ' inactive' : ''}`} >
      {inProgress && <Loader overlayContents={true} label={inProgress} />}
      <div className="list-item-row no-wrap">
        <div className="list-item-entry title project-number">
          <Link to={props.location ? `/${props.location}/orders/order/${props.project.project_id}` : null } >
            {props.projectNumDisplay(props.project.project_id, props.project.organization_code)}
          </Link>
        </div>
        {organizationID === 1 &&
          <div className="list-item-entry title project-owner">
            <Link to={props.location ? `/${props.location}/orders/order/${props.project.project_id}` : null } >
              {props.project.organization_name }
            </Link>
          </div>
        }
        <div className="list-item-entry project-owner truncate">
          <Link to={props.location ? `/${props.location}/orders/order/${props.project.project_id}` : null } >
            {projectContact && 
              `${projectContact.contact_name_first} ${projectContact.contact_name_last}`
            }
          </Link>
        </div>
        <div className="list-item-entry project-date">
          <Link to={props.location ? `/${props.location}/orders/order/${props.project.project_id}` : null } >
            <IconSVG icon="date" />{`${props.dateDisplay(props.project.project_created)} ${props.timeDisplay(props.project.project_created, false)}`}
          </Link>
        </div>
      </div>
      
      <div className="list-item-row no-wrap">
        <div className="list-item-entry project-status">
          <ProjectStatus {...props} 
                          isCancelled={isCancelled}
                          isComplete={isComplete}
                          projectStatus={projectStatus}
                          taskStatus={taskStatus} />
        </div>
        <div className="list-item-entry project-info">
          {`${itemCount} Item${itemCount > 1 ? 's' : ''} | ${orderCount > 1 || locationCount > 1 ? 'Multiple Shipments' : 'Single Shipment'}${rushCount ? ` | RUSH` : ''}`}
        </div>
      </div>
      <div className="list-item-row">
        {props.isAdmin && props.userAccess &&
          (props.userAccess.super || 
          props.userAccess.admin || 
          (props.userAccess.staffAdmin &&
          props.userAccess.staffAdmin[props.project.organization_id])) &&
          <div className="list-item-ctl">
            {!isCancelled && !isComplete &&
              <IconToken icon="complete" tooltip="Complete Order" action={loadCompleteOrder} />  
            }
            {(isCancelled || isComplete) &&
              <IconToken icon="restart" tooltip="Reactive Order" action={loadReactivateOrder} />
            }
            {!isComplete && !isCancelled &&
              <IconToken icon="cancel" tooltip="Cancel Order" action={()=>{loadCancelOrder(false)}} />
            }
            {(isCancelled || isComplete) &&
              <IconToken icon="archive" tooltip="Archive Order" action={loadArchiveOrder} />  
            }
            <IconToken icon="trash" tooltip="Remove Order" action={loadRemoveOrder} />
          </div>
        }
      </div>
      
    </div>
  )
}
