import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import IconToken from 'components/icons/IconToken';
import MediaToken from 'components/profiles/media/MediaToken';
import ActionsMenu from 'components/organisms/ActionsMenu';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import Switch from 'components/forms/elements/Switch';
import Link from 'components/navigation/Link';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode]= useState(null);
  const [redirect, setRedirect] = useState(null);
  const [activeAction, setActiveAction] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [product, setProduct] = useState(null);
  const [removingProduct, setRemovingProduct] = useState(null);
  
  
  // HOOKS
  useEffect(() => {
    return () => {
      setProduct(null);
      setRemovingProduct(null);
      setActiveAction(null);
    }
  }, []);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }  
  }, [props.languageCode]);

  useEffect(() => {
    if(props.products && props.product !== product) {
      setProduct(props.product);
    }
  }, [props.product]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    }
  },[props.userAccess]);
  
  useEffect(() => {
    if(activeAction) {
      setActiveAction(null);
    }
  }, [props.admin.products]);

  // FUNCTIONS
  function productPublish(value) {
    const params = [
      {name: 'process', value: 'publish_product'},
      {name: 'product_id', value: product.product_id},
      {name: 'language_code', value: props.curLanguage},
      {name: 'user_id', value: userID},
      {name: 'product_published', value: value},
    ]
    props[`${props.location}LoadState`](params, 'products', 'reload.products');
  }

  function productEdit() {
    setRedirect(`/admin/products/product/${product.product_id}`);
  }

  function loadProductRemove() {
    props.setModal(<ModalConfirmation
                      {...props}
                      message={`Are you sure you want to remove <b>${props.capitalizeString(product[languageCode].product_name, true)}</b>?`}
                      actionAccept={productRemove} />);
  }
  function cancelRemove() {
    props.setModal(null);
  }

  function productRemove() {
    props.setModal(null);
    const params = [
      {name: 'process', value: 'update_links'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name:'link_type', value: 'productToOrganization'},
      {name: 'link_from', value: product.product_id},
      {name: 'link_to', value: product.organization_id},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: true},
    ]
    props[`${props.location}LoadState`](params, 'links', 'reload.products');
    setActiveAction(true);
    setRemovingProduct(true);
    setTimeout(() => {
      setProduct(null);
    }, props.minToMS(.5));
  }
  
  function productEndLabel(product_end) {
    const dateProductEnd = new Date(product_end);
    const dateToday = new Date();
    if (dateProductEnd > dateToday) {
      return " | Expires: ";
    } else {
      return " | Expired: ";
    }
  }

  return (
    product && languageCode &&
    <div className={`list-item${removingProduct ? ' removed' : ''}`}>
      {redirect &&
        <Redirect to={redirect} />
      }
      {activeAction &&
        <div className="list-item-loader" >
          <Loader label="Removing..." />
        </div>
      }
      <div className="list-item-media">
        <MediaToken {...props} 
              media_file_name={
                props.objExists(product, `${languageCode}.product_image`) ?
                  props.objGetValue(product, `${languageCode}.product_image`) : 
                  'n/a'}
              media_published={true} />
      </div>
      <div className="list-item-body" onClick={() => {setRedirect(`/${props.location}/products/product/${product.product_id}`)}}>
        <Link to={`/${props.location}/products/product/${product.product_id}`} >
          <div className="product-list-item-name">{props.capitalizeString(product[languageCode].product_name, true)}</div>
          <div className="product-list-item-type">{product.organization_name} - {product[languageCode].product_type ? props.capitalizeString(product[languageCode].product_type, true) : 'No Type!'}</div>
        </Link>
        <div className="product-list-item-date">
          {`Product Code: ${product[languageCode].product_code}`}
          {product[languageCode].product_sku ? ` | Product SKU: ${product[languageCode].product_sku}` : ''}
          {product.product_end ? productEndLabel(product.product_end) + `${props.dateDisplay(product.product_end)}` : ''}
        </div>
      </div>

      {props.isAdmin && userAccess &&
        (userAccess.super || (userAccess.staffAdmin && userAccess.staffAdmin[product.organization_id]) || userAccess.productAdmin) &&

        <div className="list-item-ctl product-list-item-actions">
          <Switch label="Publish"
                  action={productPublish}
                  value={product.product_published === 1 ? true : false} />
          <ActionsMenu
              actions={[
                {label: 'Edit', icon: 'edit', action: productEdit},
                {label: 'Remove', icon: 'trash', action: loadProductRemove},
              ]}/>
        </div>
      }
    </div>
  )
}
