import React, {useEffect, useState, useRef, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import Control from 'components/atoms/Control';
import FormFieldOption from 'components/forms/form_display/FormFieldOption';
import FormFieldDisplay from 'components/forms/form_display/FormFieldDisplay';
import FormFieldOptionAddEdit from 'components/forms/common_forms/FormFieldOptionAddEdit';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
  const [optionsActive, setOptionsActive] = useState(false);
  const [fieldOrder, setFieldOrder] = useState(null);
  const fieldRef = useRef();
  const [optionIndexes, setOptionIndexes] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.objExists(props.field, 'option_groups') &&
        Array.isArray(props.field.option_groups) &&
        props.field.option_groups.length > 0) {
          let groupIndexes = {}
          props.field.option_groups.map((group, index) => {
            if(group.options && Array.isArray(group.options) &&
                group.options.length > 0) {
                  groupIndexes[group.group_id] = group.options.reduce((acc, cur) => {
                    return acc > parseInt(cur.option_order) ? acc : parseInt(cur.option_order);
                  });
            }
            if((group.options.length === 1 && groupIndexes[group.group_id] === 1) ||
                (group.options.length > 1 && groupIndexes[group.group_id] !== 1)) {
              groupIndexes[group.group_id]++;
            }
          })
          if(Object.entries(groupIndexes).length > 0) {
            setOptionIndexes(groupIndexes);
          }
    }
  }, [props.field.option_groups]);
  
  useEffect(() => {
    if(optionIndexes) {
      console.log("Here are the option indexes", optionIndexes);
    }
  }, [optionIndexes])

  // FUNCTIONS
  function modalPreview() {
    props.setModal(<FormFieldDisplay {...props}
                                      field={props.field}
                                      modalDisplay={true} />);
    fieldRef.current.classList.contains('drag-item') &&
    fieldRef.current.classList.remove('drag-item');
  }

  function handleEditField(field, isSet = false, isConditional = false, fieldset_id = null, fieldset_type = null) {
    console.log("what is happening here with the field", field, isSet, props.isConditional ? true : false);
    if(field.field_id) {
      isSet = true;
    }else{
      field = props.field;
    }

    if(props.actionEdit) {
      props.actionEdit(field, isSet, props.isConditional ? true : false, fieldset_id, fieldset_type);
    }
  }

  function handleRemoveField(e) {
    if(props.actionRemove) {
      props.actionRemove([
        {name: 'process', value: 'remove_fieldset_field'},
        {name: 'fieldset_id', value: props.fieldset_id},
        {name: 'field_id', value: props.field.field_id}
      ]);
    }
  }

  function handlePreviewField(e) {
    if(props.actionPreview) {
      props.actionPreview(e);
    }
  }

  function handleLoadOption(option = null, group_id = null, option_index = 0) {
    console.log("Option", option, "Group ID", group_id, "Index", option_index);
    props.setModal(<FormFieldOptionAddEdit {...props}
                                            option={option}
                                            group_id={group_id}
                                            option_order={option ? option.option_order :
                                                            option_index > 1 ? option_index : 1}
                                            actionAdd={handleOption}
                                            actionCancel={()=>{props.setModal(null)}} />);
  }

  function handleOption(params) {
    params.push({name: 'field_id', value: props.field.field_id});
    if(props.actionOption) {
      props.actionOption(params);
    }
  }

  function handleRemoveOption(option_id, group_id) {
    if(props.actionRemove) {
      props.actionRemove(option_id, group_id);
    }
  }
  
  function handleRemove(option_id = null, group_id = null) {
    let removeMsg = '';
    let removeAction = null;
    if(option_id && group_id) {
      removeMsg = "Are you sure you want to remove this option from the option group?";
      removeAction = ()=>{removeOption(option_id, group_id)};
    }else if(!option_id && group_id) {
      removeMsg = "Are you sure you want to remove this option group? It will also remove all options in the group.";
      removeAction = ()=>{removeOptionGroup(group_id)};
    }
    
    props.setModal(
      <ModalConfirmation {...props}
                          message={removeMsg}
                          actionCancel={()=>{props.setModal(null)}}
                          actionAccept={removeAction} />
    )
  }
  
  function removeOptionGroup(group_id) {
    props.setModal(null);
    let params = [
      {name:'process', value: 'update_links'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name:'link_type', value: 'groupToField'},
      {name: 'link_from', value: group_id},
      {name: 'link_to', value: props.field.field_id},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: 1}
    ]
    props.adminLoadState(params, 'links', 'reload.product');
  }
  
  function removeOption(option_id, group_id) {
    props.setModal(null);
    let params = [
      {name:'process', value: 'update_links'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name:'link_type', value: 'optionToGroup'},
      {name: 'link_from', value: option_id},
      {name: 'link_to', value: group_id},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: 1}
    ]
    props.adminLoadState(params, 'links', 'reload.product');
  }
  
  function handleUpdateOptionGroup(name, value, params) {
    params = [{name: name, value: value}, ...params];
    props.adminLoadState(params, 'fieldsets', 'reload.product');
  }

  function handleAddField(fieldset_id = null, option_id = null, field_count = null) {
    if(props.actionAdd) {
      props.actionAdd(fieldset_id, option_id, field_count);
    }
  }


  function handleDrag(e) {
    let target = e.target;
    while(!target.classList.contains('form-field-list-item') &&
          !target.classList.contains('conditional-field') &&
          target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    if(target.classList.contains('form-field-list-item') &&
       target.classList.contains('conditional-field')) {
     !target.classList.contains('drag-start') &&
     target.classList.add('drag-start');
    }else{
      target = fieldRef.current;
      !target.classList.contains('drag-start') &&
      target.classList.add('drag-start');
    }
  }

  function handleDragEnd(e) {
    let target = fieldRef.current;
    target.classList.contains('drag-start') &&
    target.classList.remove('drag-start');

    target.classList.contains('drag-item') &&
    target.classList.remove('drag-item');
  }

  function handleMouseDown(e) {
    setDragStyle(e);
    if(props.actionSetUpdateField) {
      props.actionSetUpdateField(props.field.field_id, props.field.field_order, props.fieldset_id);
    }
  }

  function handleMouseUp(e) {
    setDragStyle(e);
  }

  function setDragStyle(e) {
    let target = fieldRef.current;
    !target.classList.contains('drag-item') &&
    target.classList.add('drag-item');
  }

  return(
    <div className={`form-field-list-item${props.isConditional ? ` conditional-field` : ''}${optionsActive ? ' active' : ''}`}
          draggable={true}
          ref={fieldRef}
          onDrag={handleDrag}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onDragEnd={handleDragEnd}  >
      <IconSVG icon="move-vertical" className="hover-icon" />
      <div className="form-field-list-item-row">
        <div className="form-field-list-item-col field-order center" style={{flex: `0 0 2em`}}>
          <b>{props.field.field_order}</b>
        </div>
        <div className="form-field-list-item-col field-type" style={{flex: '0 0 5em'}}>
          {props.capitalizeString(props.field.field_type, true)}
        </div>
        <div className="form-field-list-item-col field-label" style={{flex: '1 0 3em'}}>
          {props.field.field_label}{props.field.field_required ? '*' : ''}{props.field.field_placeholder ?
                                      ` (${props.capitalizeString(props.field.field_placeholder)})` : ''}
        </div>
        {['select', 'multi'].includes(props.field.field_type) &&
          <div className="form-field-list-item-col ctl" style={{fled: '0 0 auto'}}>
          {props.field.option_groups &&
            Array.isArray(props.field.option_groups) &&
            props.field.option_groups.length > 0 ?
            <Control icon="add"
                      iconClass={`btn${!optionsActive ? '' : ' rotate45'}`}
                      action={()=> {setOptionsActive(optionsActive ? false : true)}}
                      showLabel={true}
                      label={`${optionsActive ? 'Hide' : 'Show'} Options`}
                      />
            : <IconToken icon="options-add" className="btn" tooltip="Add Options" action={handleLoadOption} />
          }
          </div>
        }
        <div className="form-field-list-item-col field-ctl" style={{flex: '0 0 8em'}}>
          <IconToken action={handlePreviewField} icon="eye" className="btn no-mobile" action={modalPreview} tooltip={'View Field'}/>
          {props.actionEdit && <IconToken action={()=>{handleEditField(props.field, false)}} icon="edit" className="btn" tooltip={'Edit Field'} />}
          {props.actionRemove && <IconToken action={handleRemoveField} icon="trash" className="btn" tooltip={'Remove Field'} />}
        </div>
      </div>
      {props.field.option_groups &&
      Array.isArray(props.field.option_groups) &&
      props.field.option_groups.length > 0 &&
      <div className="form-field-list-item form-field-option-group conditional">
        {props.field.option_groups.map((group, index) => {
            return (
              <Fragment>
                <div className="form-field-list-row" key={`ffsOptGrp${index}`}>
                  <div className="content">
                    <LiveFieldInput name="group_name"
                                    value={group.group_name ? group.group_name : null} 
                                    label="Option Group: "
                                    action={handleUpdateOptionGroup}
                                    optionalParams={[
                                      {name: 'process', value: 'update_field_group'},
                                      {name: 'user_id', value: props.userID},
                                      {name: 'token', value: props.token},
                                      {name: 'group_id', value: group.group_id},
                                    ]} />
                  </div>
                   <div className="content ctl">
                    <IconToken icon="options-add" 
                                className="btn" 
                                action={()=>{handleLoadOption(null, group.group_id, 
                                  optionIndexes[group.group_id] ? 
                                    optionIndexes[group.group_id] : 1)}} 
                                tooltip="Add Option To Group" />
                    <IconToken icon="trash" 
                                className="btn" 
                                tooltip="Remove Group" 
                                action={()=>{handleRemove(null, group.group_id)}} />
                  </div>
                </div>

                {group.options && Array.isArray(group.options) &&
                group.options.length > 0 &&
                group.options.map((option, index) => {
                  return <FormFieldOption {...props}
                                          key={`ffsOpt${index}`}
                                          option={option}
                                          group_id={group.group_id}
                                          actionAdd={handleLoadOption}
                                          actionEdit={()=>{handleLoadOption(option, 
                                                                            group.group_id)}}
                                          actionRemove={removeOption}
                                          actionSetUpdateField={props.actionSetUpdateField}
                                          actionEditField={handleEditField}
                                          actionAddField={props.actionAdd}
                                          actionRemoveField={handleRemoveField}  />
                })}
                
                <div className="field-list-row">
                  <Control {...props}
                            icon="add"
                            action={()=>{handleLoadOption(null, null, 1)}}
                            label="Add an option in a new group?"
                            showLabel={true} />
                </div>
              </Fragment>
            )
        })}
      </div>}
    </div>
  )
}
