import React, {useEffect, useState, useRef, Fragment} from 'react';
import {Document, Page, Outline, pdfjs} from 'react-pdf';

import Loader from 'components/atoms/Loader';
import ValueSelector from 'components/forms/elements/ValueSelector';
import Control from 'components/atoms/Control';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default (props) => {
	const [pages, setPages] = useState(null);
	const [page, setPage] = useState(1);
	const [file, setFile] = useState(null);
	const [loaded, setLoaded] = useState(null);
	const [refresh, setRefresh] = useState(null);
	const [vpWidth, setVPWidth] = useState(null);
	const [vpHeight, setVPHeight] = useState(null);
	const [approvalStatus, setApprovalStatus] = useState(null);
	
	const viewportRef = useRef();
	
	useEffect(() => {
		if(props.file) {
			if(refresh) {
				setVPWidth(viewportRef.current.clientWidth);
				setVPHeight(viewportRef.current.clientHeight);
				setFile(null);
				setRefresh(false);
				loaded && setLoaded(false);
			}else{
				setFile(props.file);	
			}
		}
	}, [props.file, props.refresh, refresh]);
	
	useEffect(() => {
		if(props.refresh && !refresh) {
			setRefresh(true);
			if(props.refresh && props.actionClearRefresh) {
				props.actionClearRefresh();
			}
		}
	}, [props.refresh]);
	
	useEffect(() => {
		if(props.approvalStatus &&
			props.approvalStatus !== 'clear' &&
			props.approvalStatus !== approvalStatus) {
			setApprovalStatus(props.approvalStatus);
		}else if((!props.approvalStatus || 
					props.approvalStatus === 'clear') &&
					approvalStatus) {
			setApprovalStatus(null);
		}
	}, [props.approvalStatus]);
	
	useEffect(()=> {
		if(props.page && 
			parseInt(props.page) > 0 &&
			parseInt(props.page) !== page) {
			setPage(parseInt(props.page));	
		}
	}, [props.page]);
	
	function onDocumentLoadSuccess({ numPages}) {
		setPages(numPages);
		setLoaded(true);
	}
	
	return (
		<div className="core-pdf">
			{!loaded && <Loader label="Loading PDF" overlayContents={true} />}
			<div className="core-pdf-viewport" ref={viewportRef}>
				<Document 
					file={file}
					onLoadSuccess={onDocumentLoadSuccess}
					renderMode='canvas'
					options={{verbosity: 0}}
					loading={
						<div
							className="core-pdf-loader" 
							style={{
							width: vpWidth ? vpWidth : 'auto',
							height: vpHeight ? vpHeight : 'auto'
							}}>
							<Loader 
								label={props.loadingMsg ? props.loadingMsg : 'Loading PDF'}
								overlayContents={true} />
						</div>
					} >
					<Page pageNumber={page || 1}
						scale={props.scale ? props.scale : 1}
						renderTextLayer={false}
						renderAnnotationLayer={false} />
					
				</Document>
				{approvalStatus &&
					<IconSVG icon="check" 
						className={`btn white ${approvalStatus === 'proof_required' ? ` caution-bg caution-border` : ` success-bg success-border`}`}
						style={{
							position: 'absolute',
							top: '.5em',
							left: '.5em',
							height: '2em',
							width: '2em',
							borderRadius: '50%',
							border: '.5em solid #333333',
						}} />
				}
			</div>
			{loaded &&
				<div className={`core-pdf-ctl ${props.stealthCtl ? ' stealth' : ''}`}
					style={{textAlign: 'center'}}>
					{!props.hidePageCtl &&
						<ValueSelector
						label="View Page"
						values={pages}
						value={page}
						actionSelect={setPage}
						centered={true} />	
					}
					{!props.hideRefresh &&
						<Fragment>
							{props.stealthCtl ?
								<IconToken 
									icon="refresh"
									className="btn reverse"
									action={()=>{setRefresh(true)}} />
							
							:
								<Control
									icon="refresh"
									label="Refresh Preview"
									showLabel={true}
									className="ctl-btn caution-bg"
									iconClass="no-btn"
									style={{opacity: 1, textAlign: 'left'}}
									action={()=>{setRefresh(true)}} />
							}
						</Fragment>
					}
				</div>
			}
		</div>
	)
}